

export default function getProducts(room, austattung_produkt){

    const productList = [];

    if(
        room.selectedEquipment["melden_rauch"] !== undefined
        && Boolean(room.selectedEquipment["melden_rauch"])
        && austattung_produkt["rauchmelder"] !== undefined
    ){
        productList.push({
            product: austattung_produkt["rauchmelder"],
            count: 1
        })
    }
    return productList;
}

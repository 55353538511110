

export default function getProducts(room, austattung_produkt){

    const productList = [];

    if(
        room.selectedEquipment["radio_datenanschluss_kabel"] !== undefined
        && room.selectedEquipment["radio_datenanschluss_kabel"] > 0
        && austattung_produkt["kabel-end"] !== undefined
        && austattung_produkt["kabel-durch"] !== undefined
        && austattung_produkt["kabel-zentralstueck"] !== undefined
    ){
        if(room.selectedEquipment["radio_datenanschluss_kabel"] > 1){
            productList.push({
                product: austattung_produkt["kabel-durch"],
                count: room.selectedEquipment["radio_datenanschluss_kabel"] - 1
            });
        }
        productList.push({
            product: austattung_produkt["kabel-end"],
            count: 1
        });
        productList.push({
            product: austattung_produkt["kabel-zentralstueck"],
            count: room.selectedEquipment["radio_datenanschluss_kabel"]
        });
    }

    return productList;
}

import React from "react";
import ReactDOM from "react-dom";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import roomList_de from "./../data/availableRooms.json";
import roomList_en from "./../data/availableRooms_en.json";
import roomList_es from "./../data/availableRooms_es.json";
import roomList_nl from "./../data/availableRooms_nl.json";
import roomList_fr from "./../data/availableRooms_fr.json";
import roomList_it from "./../data/availableRooms_it.json";
import {useTranslation} from "react-i18next";

export default function AddRoomComponent({onAddRoom}){
    const el = document.createElement('a');
    document.body.appendChild(el);
    const [opened, setOpened] = React.useState(false);
    function onAddRoomInner(room){
        onAddRoom(room);
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [marginLeft, setMarginLeft] = React.useState(0);

    const handleClick = (event) => {
        setMarginLeft(ReactDOM.findDOMNode(event.currentTarget).clientWidth);
        setAnchorEl(event.currentTarget);
        setOpened(true);
    };

    const handleClose = () => {
        setOpened(false);
    };
    const {t, i18n} = useTranslation();
    const language = i18n.resolvedLanguage;
    const roomList = getRoomList();

    function getRoomList() {
        switch (language) {
            case 'en': return roomList_en.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0));
            case 'nl': return roomList_nl.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0));
            case 'fr': return roomList_fr.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0));
            case 'it': return roomList_it.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0));
            case 'es': return roomList_es.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0));
            case 'de': default: return roomList_de.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0));
        }
    }
    function MenuItemContent(txt) {
        return <div dangerouslySetInnerHTML={{__html: txt}}></div>;
    }

    return <div className="addRoomWrapper">
        <div className="header">
            <div className="button" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <AddCircleIcon />
                <span>{t('app.button.addRoom')}</span>
            </div>
            {'parentIFrame' in window
            ? <Menu
                id="simple-menu"
                anchorEl={el}
                keepMounted
                open={true}
                onClose={handleClose}
                style={{marginLeft: (0.8 * marginLeft) + "px", visibility: opened ? 'visible' : 'hidden'}}
            >
                {roomList.map(r => <MenuItem key={r.type} style={{fontSize: "inherit"}} className="addRoomRoomListItem" onClick={event => onAddRoomInner(r)}>
                    <div dangerouslySetInnerHTML={{__html: r.name}}></div>
                </MenuItem>)}
            </Menu>
            : <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={opened}
                onClose={handleClose}
                style={{marginLeft: (0.8 * marginLeft) + "px", visibility: opened ? 'visible' : 'hidden'}}
            >
                    {roomList.map(r => <MenuItem key={r.type} style={{fontSize: "inherit"}} className="addRoomRoomListItem" onClick={event => onAddRoomInner(r)}>
                        <div dangerouslySetInnerHTML={{__html: r.name}}></div>
                    </MenuItem>)}
            </Menu>}
        </div>
    </div>
}

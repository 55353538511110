import React from "react";
import CountBox from "./CountBox/CountBox";
import LabelBox from "./LabelBox/LabelBox";
import availableRooms_de from "./../data/availableRooms.json"
import availableRooms_at from "./../data/availableRooms_at.json"
import availableRooms_en from "./../data/availableRooms_en.json"
import availableRooms_es from "./../data/availableRooms_es.json"
import availableRooms_fr from "./../data/availableRooms_fr.json"
import availableRooms_it from "./../data/availableRooms_it.json"
import availableRooms_nl from "./../data/availableRooms_nl.json"
import {useTranslation} from "react-i18next";

export default function RoomComponent({room, updateRoom, deleteRoom, showMouseOver, tasterCursor = null, col = 1, jumpTo, project}){

    const {t, i18n} = useTranslation();
    const language = i18n.resolvedLanguage;
    function rename(name){
        updateRoom({...room, name: name});
    }
    function onDelete(e){
        deleteRoom(room);
    }
    function setDoorCount(value){
        const wasWoDoor = room.doorCount < 1;
        let newRoom = {...room, doorCount: value};
        let availableRoom = null;
        if (wasWoDoor && value > 0) {
            switch (language) {
                case 'de': availableRoom = availableRooms_de.filter(i => i.typePro === room.typePro)[0]; break;
                case 'at': availableRoom = availableRooms_at.filter(i => i.typePro === room.typePro)[0]; break;
                case 'en': availableRoom = availableRooms_en.filter(i => i.typePro === room.typePro)[0]; break;
                case 'es': availableRoom = availableRooms_es.filter(i => i.typePro === room.typePro)[0]; break;
                case 'fr': availableRoom = availableRooms_fr.filter(i => i.typePro === room.typePro)[0]; break;
                case 'it': availableRoom = availableRooms_it.filter(i => i.typePro === room.typePro)[0]; break;
                case 'nl': availableRoom = availableRooms_nl.filter(i => i.typePro === room.typePro)[0]; break;
            }
            if (availableRoom && availableRoom.defaultEquipment && availableRoom.defaultEquipment[project.selectedLevel-1] && availableRoom.defaultEquipment[project.selectedLevel-1].values) {
                newRoom = {...newRoom, selectedEquipment: {...newRoom.selectedEquipment, 'schaltbare_beleuchtung': availableRoom.defaultEquipment[project.selectedLevel-1].values.schaltbare_beleuchtung}};
                newRoom = {...newRoom, selectedEquipment: {...newRoom.selectedEquipment, 'dimmbare_beleuchtung': availableRoom.defaultEquipment[project.selectedLevel-1].values.dimmbare_beleuchtung}};
            }
        } else if (value < 1) {
            newRoom = {...newRoom, selectedEquipment: {...newRoom.selectedEquipment, 'schaltbare_beleuchtung': 0}};
            newRoom = {...newRoom, selectedEquipment: {...newRoom.selectedEquipment, 'dimmbare_beleuchtung': 0}};
        }
        updateRoom(newRoom);
    }
    function setWindowCountCount(value){
        updateRoom({...room, windowCount: value, selectedEquipment: {...room.selectedEquipment, verschattung_steuern: value < 1 ? 0 : room.selectedEquipment.verschattung_steuern}});
    }
    function setHeatingCountCount(value){
        updateRoom({...room, heatingCount: value, selectedEquipment: {...room.selectedEquipment, heizen_heizkoerper: value < 1 ? 0 : room.selectedEquipment.heizen_heizkoerper}});
    }
    function setFloorheatingCountCount(value){
        updateRoom({...room, floorheatingCount: value, selectedEquipment: {...room.selectedEquipment, heizen_fußbodenheizung: value < 1 ? 0 : room.selectedEquipment.heizen_fußbodenheizung}});
    }

    return <div className={"roomRow row" + (showMouseOver ? " mouseover" : "")}>
        <div className="col-5 contextBoxHover">
            <LabelBox value={room.name} setValue={rename} deleteItem={onDelete}/>
        </div>
        <div className={"phone-w-50"}>
            <div className={"text-center col-3 w-188" + (col === 1 ? ' active' : ' hidden')}><CountBox minValue={0} maxValue={5} value={room.doorCount} setValue={setDoorCount} jumpTo={jumpTo}/></div>
            <div className={"text-center col-3 w-188" + (col === 2 ? ' active' : ' hidden')}><CountBox minValue={0} maxValue={99} value={room.windowCount} setValue={setWindowCountCount} jumpTo={jumpTo}/></div>
            <div className={"text-center col-3 w-188" + (col === 3 ? ' active' : ' hidden')}><CountBox minValue={0} maxValue={99} value={room.heatingCount} setValue={setHeatingCountCount} jumpTo={jumpTo}/></div>
            <div className={"text-center col-3 w-188" + (col === 4 ? ' active' : ' hidden')}><CountBox minValue={0} maxValue={99} value={room.floorheatingCount} setValue={setFloorheatingCountCount} jumpTo={jumpTo}/></div>
        </div>
    </div>;
}

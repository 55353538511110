import React from 'react';
import { createRoot } from 'react-dom/client';
import "./index.scss"
import {BrowserRouter as Router} from "react-router-dom";
import RouterComponent from "./components/RouterComponent";
import './i18n';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Router>
    <RouterComponent />
</Router>);




export default function getProducts(project, austattung_produkt){

    const productList = [];
    if(
        project.selectedEquipment['sound'] !== undefined
        && project.selectedEquipment['sound'] > 0
    ) {
        productList.push({
            product: austattung_produkt['server'],
            count: 1
        });
    }

    return productList;
}

import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
// import detector from 'i18next-browser-languagedetector'
import translations_en from "./data/translation_en.json";
import translations_nl from "./data/translation_nl.json";
import translations_fr from "./data/translation_fr.json";
import translations_it from "./data/translation_it.json";
import translations_at from "./data/translation_at.json";
import translations_de from "./data/translation_de.json";
import translations_es from "./data/translation_es.json";

const resources = {
    en: { translation: translations_en },
    nl: { translation: translations_nl },
    fr: { translation: translations_fr },
    it: { translation: translations_it },
    at: { translation: translations_at },
    de: { translation: translations_de },
    es: { translation: translations_es }
};
const location = window.location.host;
const loc = window.location.pathname;
const path = loc.replace(location,'');
function getCurrentLanguage() {
    let lng = 'de';
    if (location.indexOf('planning') >= 0 || path.indexOf('/de/') >= 0) { lng = 'en'; }
    if (location.indexOf('planner') >= 0 || path.indexOf('/nl/') >= 0) { lng = 'nl'; }
    if (location.indexOf('planification') >= 0 || path.indexOf('/fr/') >= 0) { lng = 'fr'; }
    if (location.indexOf('pianificazione') >= 0 || path.indexOf('/it/') >= 0) { lng = 'it'; }
    if (location.indexOf('planer') >= 0 || path.indexOf('/at/') >= 0) { lng = 'at'; }
    console.log('current Language is', lng, location, path);
    return lng;
}
i18n
    // .use(detector)
    .use(initReactI18next)
    .init({
        // debug: true,
        resources,
        fallbackLng: 'de',
        lng: getCurrentLanguage(),
        interpolation: { escapeValue: false }
    });
export default i18n;



export default function getProducts(room, austattung_produkt){

    const productList = [];

    if(
        room.selectedEquipment["steckdosen"] !== undefined
        && room.selectedEquipment["steckdosen"] > 0
        && austattung_produkt["steckdosen"] !== undefined
    ){
        productList.push({
            product: austattung_produkt["steckdosen"],
            count: room.selectedEquipment["steckdosen"]
        })
    }
    if(
        room.selectedEquipment["schaltbare_steckdosen"] !== undefined
        && room.selectedEquipment["schaltbare_steckdosen"] > 0
        && austattung_produkt["steckdosen"] !== undefined
    ){
        productList.push({
            product: austattung_produkt["steckdosen"],
            count: room.selectedEquipment["schaltbare_steckdosen"]
        })
    }
    return productList;
}

import React, {useEffect} from "react";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ReactDOM from "react-dom";
import {useTranslation} from "react-i18next";
import floorList_de from "../data/availableFloors.json";
import floorList_en from "../data/availableFloors_en.json";
import floorList_nl from "../data/availableFloors_nl.json";
import floorList_fr from "../data/availableFloors_fr.json";
import floorList_it from "../data/availableFloors_it.json";
import floorList_es from "../data/availableFloors_es.json";

export default function AddFloorComponent({onAddFloor}){
    const {t, i18n} = useTranslation();
    const language = i18n.resolvedLanguage;
    const floorList = getFloorList();

    const el = document.createElement('a');
    document.body.appendChild(el);
    function getFloorList() {
        switch (language) {
            case 'en': return floorList_en.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0));
            case 'nl': return floorList_nl.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0));
            case 'fr': return floorList_fr.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0));
            case 'it': return floorList_it.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0));
            case 'es': return floorList_es.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0));
            case 'de': default: return floorList_de.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0));
        }
    }
    const [opened, setOpened] = React.useState(false);
    useEffect(() => {
        // if (!isIFrame) { setTimeout(() => {document.body.style.overflow = 'unset'; }, 200); }
        // if ('parentIFrame' in window) {
        //     const btn = document.getElementsByClassName('theclick');
        //     setTimeout(() => {
        //         console.log('triggering first click');
        //         btn.click();
        //         setTimeout(() => {
        //             console.log('second click');
        //             btn.click();
        //         }, 100);
        //     },100);
        // }
    }, []);

    function onAddFloorInner(floor){
        onAddFloor(floor);
        handleClose();
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [marginLeft, setMarginLeft] = React.useState(0);

    const handleClick = (event) => {
        event.preventDefault(); event.stopPropagation();
        setMarginLeft(ReactDOM.findDOMNode(event.currentTarget).clientWidth);
        setAnchorEl(event.currentTarget);
        setOpened(true);
        return false;
    };

    const handleClose = () => {
        setOpened(false);
    };

    return <div className="addFloorWrapper">
        <div className="header">
            <div className="button theclick" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <AddCircleIcon />
                <span>{t('app.button.addFloor')}</span>
            </div>
            {'parentIFrame' in window
            ? <Menu
                id="simple-menu"
                anchorEl={el}
                keepMounted
                open={true}
                onClose={handleClose}
                style={{marginLeft: (0.8 * marginLeft) + "px", visibility: opened ? 'visible' : 'hidden'}}
            >
                {floorList.map(r => <MenuItem key={r.type} style={{fontSize: "inherit"}} className="addFloorFloorListItem" onClick={event => onAddFloorInner(r)}>{r.name}</MenuItem>)}
            </Menu>
            : <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={opened}
                onClose={handleClose}
                style={{marginLeft: (0.8 * marginLeft) + "px", visibility: opened ? 'visible' : 'hidden'}}
            >
                {floorList.map(r => <MenuItem key={r.type} style={{fontSize: "inherit"}} className="addFloorFloorListItem" onClick={event => onAddFloorInner(r)}>{r.name}</MenuItem>)}
            </Menu>}
        </div>
    </div>
}

import React from "react"
import bgHome1 from '../images/bg-home-1.png';
import screenshot from '../images/screenshot01.png';

export default function HomeSubPagesComponent({project, startProject}){
    return <div>
        <div className="box subpage">
            <img src={bgHome1} width="100%" alt=""/>
            <div className="page">
                <div className="left">
                    <h2>Gebäudestruktur anlegen</h2>
                    <p>Blindcopy zur Beschreibung der Funktion an dieser Stelle in erklärender Kurzform. Blindcopy zur Beschreibung der Funktion an dieser Stelle in erklärender Kurzform. Blindcopy zur Beschreibung der Funktion an dieser Stelle in erklärender Kurzform.</p>
                </div>
                <div className="right">
                    <img src={screenshot} alt="" />
                </div>
            </div>
        </div>
        <div className="box subpage">
            <img src={bgHome1} width="100%" alt=""/>
            <div className="page">
                <div className="left">
                    <h2>Ausstattungsumfang definieren</h2>
                    <p>Blindcopy zur Beschreibung der Funktion an dieser Stelle in erklärender Kurzform. Blindcopy zur Beschreibung der Funktion an dieser Stelle in erklärender Kurzform. Blindcopy zur Beschreibung der Funktion an dieser Stelle in erklärender Kurzform.</p>
                </div>
                <div className="right">
                    <img src={screenshot} alt="" />
                </div>
            </div>
        </div>
        <div className="box subpage">
            <img src={bgHome1} width="100%" alt=""/>
            <div className="page">
                <div className="left">
                    <h2>Schalterdesign auswählen</h2>
                    <p>Blindcopy zur Beschreibung der Funktion an dieser Stelle in erklärender Kurzform. Blindcopy zur Beschreibung der Funktion an dieser Stelle in erklärender Kurzform. Blindcopy zur Beschreibung der Funktion an dieser Stelle in erklärender Kurzform.</p>
                </div>
                <div className="right">
                    <img src={screenshot} alt="" />
                </div>
            </div>
        </div>
        <div className="box subpage">
            <img src={bgHome1} width="100%" alt=""/>
            <div className="page">
                <div className="left">
                    <h2>Projektliste anzeigen lassen</h2>
                    <p>Blindcopy zur Beschreibung der Funktion an dieser Stelle in erklärender Kurzform. Blindcopy zur Beschreibung der Funktion an dieser Stelle in erklärender Kurzform. Blindcopy zur Beschreibung der Funktion an dieser Stelle in erklärender Kurzform.</p>
                </div>
                <div className="right">
                    <img src={screenshot} alt="" />
                </div>
            </div>
        </div>
        {/*<div className="box subpage">*/}
            {/*<div className="page">*/}
                {/*<ColorButton1 onClick={startProject}>*/}
                    {/*<span className={"headerMenuItem"}>Planung starten</span>*/}
                {/*</ColorButton1>*/}
                {/*<div className="clearfix"></div>*/}
            {/*</div>*/}
        {/*</div>*/}
    </div>
}

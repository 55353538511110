import {useParams} from "react-router-dom";
import { OAuthPopup, useOAuth2 } from "@tasoskakour/react-use-oauth2";
import StartComponent from "./StartComponent";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import Slide from "@material-ui/core/Slide";
import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";


export default function MyLoginComponent(){

    const params = useParams();
    const [resetDialogOpen, setResetDialogOpen] = React.useState(true);
    const {t, i18n} = useTranslation();
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const { data, loading, error, getAuth, logout, isPersistent } = useOAuth2({
        authorizeUrl: process.env.REACT_APP_QUICK_OAUTH2_AUTHORIZE_URL + '/authorize',
        clientId: getClientId(),
        redirectUri: document.location.origin + '/' + params.confType + '/myjung/callback',
        scope: "my-jung-id",
        responseType: "code",
        exchangeCodeForTokenServerURL: process.env.REACT_APP_QUICK_BACKEND_URL + "/configurator-api/quickplaner/OAuth2/token",
        exchangeCodeForTokenMethod: "POST",
        onSuccess: (payload) => console.log("Success", payload),
        onError: (error_) => console.log("Error", error_)
    });

    const isLoggedIn = Boolean(data?.access_token); // or whatever...
    const ColorButton = withStyles((theme) => ({
        root: {
            color: "var(--weiss)",
            backgroundColor: "var(--color1)",
            '&:hover': {
                backgroundColor:"#8cadbf",
                borderColor: "#8cadbf"
            },
            borderRadius: 0,
            width: "143px",
            height: "45px",
            lineHeight: "45px",
            fontSize: "20px",
            textAlign: "center",
            margin: "20px",
            textTransform: "inherit",
            padding: "8px 16px"
        },
    }))(Button);

    function getClientId(){
        switch(params.confType) {
            case "schalter":
            case "switch-configurator":
                return  "jung-configurator-switch";
            case "home":
            case "home-configurator":
                return "jung-configurator-home";
            case "knx":
            case "knx-configurator":
                return "jung-configurator-knx";
            default:
                return null;
        }
    }
    function renderSwitch() {
        switch(true) {
            case isLoggedIn:
                return  <StartComponent isMyJung={true} />;
            default:
                return <>
                    <StartComponent isMyJung={true} />
                    <div style={{filter: "blur(4px)", pointerEvents: "none", position: 'absolute', top:'0',left:'0',right:'0',bottom:'0', background:'rgba(255, 255, 255, 0.75)', zIndex: 998}}></div>
                    <Dialog
                        open={resetDialogOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogContent>
                            <div style={{display: 'flex', flexDirection: 'column', padding:'20px 65px 0'}}>
                                <p style={{textAlign:'left', color: '#707070', fontSize: '32px'}}>
                                    {t('app.myJung.login')}
                                </p>
                                <p style={{textAlign:'left', color: '#707070', fontSize: '20px', lineHeight: '26px'}}>
                                    {t('app.myJung.text')} <a className={"link-jung"}
                                                              href={"https://www.jung-group.com/de-DE/account/login"} target={"_blank"}
                                                              style={{color:'#5b8aa4', textDecoration:'none'}}>{t('app.myJung.linkText')}</a>.
                                </p>
                            </div>
                            <div style={{textAlign:'right', paddingBottom: '40px', paddingRight: '65px'}}>{renderSwitch2()}</div>
                        </DialogContent>
                    </Dialog>
                    {/*<div style={{position: "fixed", top: "0", left: "0", right: '0', bottom: '0', zIndex: 999, display: 'flex', alignItems: 'flex-start'}}>*/}
                    {/*    <div style={{margin: "15% auto auto", width: "30%", background: "#5B8AA4", padding: "50px", textAlign: "center"}}>*/}
                    {/*        <p>*/}
                    {/*            myJUNG Login*/}
                    {/*        </p>*/}
                    {/*        {renderSwitch2()}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </>;
        }
    }

    function renderSwitch2(){
        switch(true) {
            case isLoggedIn:
                return  <StartComponent isMyJung={true} />;
            case loading:
                return <div>Loading...</div>;
            case error:
                return <div>Error</div>;
            default:
                return <><ColorButton style={{ margin: "24px 0", cursor: "pointer", borderRadius: '3px'}} type="button" className={"jung-button"} onClick={() => getAuth()}>Login</ColorButton></>;
        }

    }

    return <>{renderSwitch()}</>;
}

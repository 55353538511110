import {v4 as uuidv4} from "uuid";

export default function getProducts(room, austattung_produkt, selectedAlternatives, existingActions){

    const productList = [];
    let actions = [];

    function getNewUuid() {
        const uuid = uuidv4();
        let found = actions.filter(i => i.uuid === uuid)[0] !== undefined;
        if (found) { return getNewUuid(); }
        found = existingActions.filter(i => i.uuid === uuid)[0] !== undefined;
        if (found) { return getNewUuid(); }
        return uuid;
    }
    function addActionForDevice(device, item, nr) {
        const retList = [];
        for (let i = 0; i < nr; i++) {
            switch (item.key) {
                case "schaltbare_beleuchtung": //switch_light
                    const nAction = {type: 'switch_light', uuid: getNewUuid(), device, addedIn: 'AddTaster'};
                    actions.push(nAction); retList.push(nAction);
                    break;
                case "schaltbare_steckdosen": //switch_powerplug
                    const nActionp = {type: 'switch_powerplug', uuid: getNewUuid(), device, addedIn: 'AddTaster'};
                    actions.push(nActionp); retList.push(nActionp);
                    break;
                case "dimmbare_beleuchtung": //dim
                    const nActiond = {type: 'dim', uuid: getNewUuid(), device, addedIn: 'AddTaster'};
                    actions.push(nActiond); retList.push(nActiond);
                    break;
                case "verschattung_steuern": //shading_shutter
                case "rolllaeden_steuern":
                case "jalousie_steuern":
                    const nActionj = {type: 'shading_shutter', uuid: getNewUuid(), device, addedIn: 'AddTaster'};
                    actions.push(nActionj); retList.push(nActionj);
                    break;
                case "heizen_heizkoerper":
                case "heizen_fußbodenheizung":
                    const nActionh = {type: 'shading_shutter', uuid: getNewUuid(), device, addedIn: 'AddTaster'};
                    actions.push(nActionh); retList.push(nActionh);
                    break;
                default: break;
            }
        }
        return retList;
    }

    const checkList = [
        {key: "schaltbare_beleuchtung", onlyFirst: false, matrix: "schalten"},
        {key: "dimmbare_beleuchtung", onlyFirst: false, matrix: "dimmen"},
        {key: "heizen_fußbodenheizung", onlyFirst: true, matrix: "heizen"},
        {key: "heizen_heizkoerper", onlyFirst: true, matrix: "heizen"},
        {key: "verschattung_steuern", onlyFirst: true, matrix: "verschatten"},
        {key: "rolllaeden_steuern", onlyFirst: true, matrix: "verschatten"},
        {key: "jalousie_steuern", onlyFirst: true, matrix: "verschatten"}
    ];
    const matrix = {
        "schalten": [
            {doorCount: 1, counter: 1, items: [{key: "1701SE", count: 1,}]},
            {doorCount: 1, counter: 2, items: [{key: "1702SE", count: 1}]},
            {doorCount: 1, counter: 3, items: [{key: "1701SE", count: 1}, {key: "1702SE", count: 1}]},
            {doorCount: 1, counter: 4, items: [{key: "1702SE", count: 2}]},
            {doorCount: 1, counter: 5, items: [{key: "1701SE", count: 1}, {key: "1702SE", count: 2}]},
            {doorCount: 1, counter: 6, items: [{key: "1702SE", count: 3}]},
            {doorCount: 1, counter: 7, items: [{key: "1701SE", count: 1}, {key: "1702SE", count: 3}]},
            {doorCount: 1, counter: 8, items: [{key: "1702SE", count: 4}]},
            {doorCount: 1, counter: 9, items: [{key: "1701SE", count: 1}, {key: "1702SE", count: 4}]},
            {doorCount: 1, counter: 10, items: [{key: "1702SE", count: 5}]},

            {doorCount: 2, counter: 1, items: [{key: "1701SE", count: 1}, {key: "1723NE-W1", count: 1}]},
            {doorCount: 2, counter: 2, items: [{key: "1702SE", count: 1}, {key: "1723NE-W2", count: 1}]},
            {doorCount: 2, counter: 3, items: [{key: "1701SE", count: 1}, {key: "1702SE", count: 1}, {key: "1723NE-W2", count: 1}]},
            {doorCount: 2, counter: 4, items: [{key: "1702SE", count: 2}, {key: "1723NE-W2", count: 1}]},
            {doorCount: 2, counter: 5, items: [{key: "1701SE", count: 1}, {key: "1702SE", count: 2}, {key: "1723NE-W2", count: 1}]},
            {doorCount: 2, counter: 6, items: [{key: "1702SE", count: 3}, {key: "1723NE-W2", count: 2}]},
            {doorCount: 2, counter: 7, items: [{key: "1701SE", count: 1}, {key: "1702SE", count: 3}, {key: "1723NE-W2", count: 2}]},
            {doorCount: 2, counter: 8, items: [{key: "1702SE", count: 4}, {key: "1723NE-W2", count: 2}]},
            {doorCount: 2, counter: 9, items: [{key: "1701SE", count: 1}, {key: "1702SE", count: 4}, {key: "1723NE-W2", count: 2}]},
            {doorCount: 2, counter: 10, items: [{key: "1702SE", count: 5}, {key: "1723NE-W2", count: 2}]},

            {doorCount: 3, counter: 1, items: [{key: "1701SE", count: 1}, {key: "1723NE-W1", count: 2}]},
            {doorCount: 3, counter: 2, items: [{key: "1702SE", count: 1}, {key: "1723NE-W2", count: 2}]},
            {doorCount: 3, counter: 3, items: [{key: "1701SE", count: 1}, {key: "1702SE", count: 1}, {key: "1723NE-W2", count: 2}]},
            {doorCount: 3, counter: 4, items: [{key: "1702SE", count: 2}, {key: "1723NE-W2", count: 2}]},
            {doorCount: 3, counter: 5, items: [{key: "1701SE", count: 1}, {key: "1702SE", count: 2}, {key: "1723NE-W2", count: 2}]},
            {doorCount: 3, counter: 6, items: [{key: "1702SE", count: 3}, {key: "1723NE-W2", count: 2}]},
            {doorCount: 3, counter: 7, items: [{key: "1701SE", count: 1}, {key: "1702SE", count: 3}, {key: "1723NE-W2", count: 2}]},
            {doorCount: 3, counter: 8, items: [{key: "1702SE", count: 4}, {key: "1723NE-W2", count: 2}]},
            {doorCount: 3, counter: 9, items: [{key: "1701SE", count: 1}, {key: "1702SE", count: 4}, {key: "1723NE-W2", count: 2}]},
            {doorCount: 3, counter: 10, items: [{key: "1702SE", count: 5}, {key: "1723NE-W2", count: 2}]},

            {doorCount: 4, counter: 1, items: [{key: "1701SE", count: 1}, {key: "1723NE-W1", count: 3}]},
            {doorCount: 4, counter: 2, items: [{key: "1702SE", count: 1}, {key: "1723NE-W2", count: 3}]},
            {doorCount: 4, counter: 3, items: [{key: "1701SE", count: 1}, {key: "1702SE", count: 1}, {key: "1723NE-W2", count: 3}]},
            {doorCount: 4, counter: 4, items: [{key: "1702SE", count: 2}, {key: "1723NE-W2", count: 3}]},
            {doorCount: 4, counter: 5, items: [{key: "1701SE", count: 1}, {key: "1702SE", count: 2}, {key: "1723NE-W2", count: 3}]},
            {doorCount: 4, counter: 6, items: [{key: "1702SE", count: 3}, {key: "1723NE-W2", count: 3}]},
            {doorCount: 4, counter: 7, items: [{key: "1701SE", count: 1}, {key: "1702SE", count: 3}, {key: "1723NE-W2", count: 3}]},
            {doorCount: 4, counter: 8, items: [{key: "1702SE", count: 4}, {key: "1723NE-W2", count: 3}]},
            {doorCount: 4, counter: 9, items: [{key: "1701SE", count: 1}, {key: "1702SE", count: 4}, {key: "1723NE-W2", count: 3}]},
            {doorCount: 4, counter: 10, items: [{key: "1702SE", count: 5}, {key: "1723NE-W2", count: 3}]},

            {doorCount: 5, counter: 1, items: [{key: "1701SE", count: 1}, {key: "1723NE-W1", count: 4}]},
            {doorCount: 5, counter: 2, items: [{key: "1702SE", count: 1}, {key: "1723NE-W2", count: 4}]},
            {doorCount: 5, counter: 3, items: [{key: "1701SE", count: 1}, {key: "1702SE", count: 1}, {key: "1723NE-W2", count: 4}]},
            {doorCount: 5, counter: 4, items: [{key: "1702SE", count: 2}, {key: "1723NE-W2", count: 4}]},
            {doorCount: 5, counter: 5, items: [{key: "1701SE", count: 1}, {key: "1702SE", count: 2}, {key: "1723NE-W2", count: 4}]},
            {doorCount: 5, counter: 6, items: [{key: "1702SE", count: 3}, {key: "1723NE-W2", count: 4}]},
            {doorCount: 5, counter: 7, items: [{key: "1701SE", count: 1}, {key: "1702SE", count: 3}, {key: "1723NE-W2", count: 4}]},
            {doorCount: 5, counter: 8, items: [{key: "1702SE", count: 4}, {key: "1723NE-W2", count: 4}]},
            {doorCount: 5, counter: 9, items: [{key: "1701SE", count: 1}, {key: "1702SE", count: 4}, {key: "1723NE-W2", count: 4}]},
            {doorCount: 5, counter: 10, items: [{key: "1702SE", count: 5}, {key: "1723NE-W2", count: 4}]},
        ],
        "dimmen": [
            {doorCount: 1, counter: 1, items: [{key: "1711DE", count: 1}]},
            {doorCount: 1, counter: 2, items: [{key: "1712DE", count: 1}]},
            {doorCount: 1, counter: 3, items: [{key: "1711DE", count: 1}, {key: "1712DE", count: 1}]},
            {doorCount: 1, counter: 4, items: [{key: "1712DE", count: 2}]},
            {doorCount: 1, counter: 5, items: [{key: "1711DE", count: 1}, {key: "1712DE", count: 2}]},
            {doorCount: 1, counter: 6, items: [{key: "1712DE", count: 3}]},
            {doorCount: 1, counter: 7, items: [{key: "1711DE", count: 1}, {key: "1712DE", count: 3}]},
            {doorCount: 1, counter: 8, items: [{key: "1712DE", count: 4}]},
            {doorCount: 1, counter: 9, items: [{key: "1711DE", count: 1}, {key: "1712DE", count: 4}]},
            {doorCount: 1, counter: 10, items: [{key: "1712DE", count: 5}]},

            {doorCount: 2, counter: 1, items: [{key: "1711DE", count: 1}, {key: "1723NE-W1", count: 1}]},
            {doorCount: 2, counter: 2, items: [{key: "1712DE", count: 1}, {key: "1723NE-W2", count: 1}]},
            {doorCount: 2, counter: 3, items: [{key: "1711DE", count: 1}, {key: "1712DE", count: 1}, {key: "1723NE-W2", count: 1}]},
            {doorCount: 2, counter: 4, items: [{key: "1712DE", count: 2}, {key: "1723NE-W2", count: 1}]},
            {doorCount: 2, counter: 5, items: [{key: "1711DE", count: 1}, {key: "1712DE", count: 2}, {key: "1723NE-W2", count: 1}]},
            {doorCount: 2, counter: 6, items: [{key: "1712DE", count: 3}, {key: "1723NE-W2", count: 2}]},
            {doorCount: 2, counter: 7, items: [{key: "1711DE", count: 1}, {key: "1712DE", count: 3}, {key: "1723NE-W2", count: 2}]},
            {doorCount: 2, counter: 8, items: [{key: "1712DE", count: 4}, {key: "1723NE-W2", count: 2}]},
            {doorCount: 2, counter: 9, items: [{key: "1711DE", count: 1}, {key: "1712DE", count: 4}, {key: "1723NE-W2", count: 2}]},
            {doorCount: 2, counter: 10, items: [{key: "1712DE", count: 5}, {key: "1723NE-W2", count: 2}]},

            {doorCount: 3, counter: 1, items: [{key: "1711DE", count: 1}, {key: "1723NE-W1", count: 2}]},
            {doorCount: 3, counter: 2, items: [{key: "1712DE", count: 1}, {key: "1723NE-W2", count: 2}]},
            {doorCount: 3, counter: 3, items: [{key: "1711DE", count: 1}, {key: "1712DE", count: 1}, {key: "1723NE-W2", count: 2}]},
            {doorCount: 3, counter: 4, items: [{key: "1712DE", count: 2}, {key: "1723NE-W2", count: 2}]},
            {doorCount: 3, counter: 5, items: [{key: "1711DE", count: 1}, {key: "1712DE", count: 2}, {key: "1723NE-W2", count: 2}]},
            {doorCount: 3, counter: 6, items: [{key: "1712DE", count: 3}, {key: "1723NE-W2", count: 2}]},
            {doorCount: 3, counter: 7, items: [{key: "1711DE", count: 1}, {key: "1712DE", count: 3}, {key: "1723NE-W2", count: 2}]},
            {doorCount: 3, counter: 8, items: [{key: "1712DE", count: 4}, {key: "1723NE-W2", count: 2}]},
            {doorCount: 3, counter: 9, items: [{key: "1711DE", count: 1}, {key: "1712DE", count: 4}, {key: "1723NE-W2", count: 2}]},
            {doorCount: 3, counter: 10, items: [{key: "1712DE", count: 5}, {key: "1723NE-W2", count: 2}]},

            {doorCount: 4, counter: 1, items: [{key: "1711DE", count: 1}, {key: "1723NE-W1", count: 3}]},
            {doorCount: 4, counter: 2, items: [{key: "1712DE", count: 1}, {key: "1723NE-W2", count: 3}]},
            {doorCount: 4, counter: 3, items: [{key: "1711DE", count: 1}, {key: "1712DE", count: 1}, {key: "1723NE-W2", count: 3}]},
            {doorCount: 4, counter: 4, items: [{key: "1712DE", count: 2}, {key: "1723NE-W2", count: 3}]},
            {doorCount: 4, counter: 5, items: [{key: "1711DE", count: 1}, {key: "1712DE", count: 2}, {key: "1723NE-W2", count: 3}]},
            {doorCount: 4, counter: 6, items: [{key: "1712DE", count: 3}, {key: "1723NE-W2", count: 3}]},
            {doorCount: 4, counter: 7, items: [{key: "1711DE", count: 1}, {key: "1712DE", count: 3}, {key: "1723NE-W2", count: 3}]},
            {doorCount: 4, counter: 8, items: [{key: "1712DE", count: 4}, {key: "1723NE-W2", count: 3}]},
            {doorCount: 4, counter: 9, items: [{key: "1711DE", count: 1}, {key: "1712DE", count: 4}, {key: "1723NE-W2", count: 3}]},
            {doorCount: 4, counter: 10, items: [{key: "1712DE", count: 5}, {key: "1723NE-W2", count: 3}]},

            {doorCount: 5, counter: 1, items: [{key: "1711DE", count: 1}, {key: "1723NE-W1", count: 4}]},
            {doorCount: 5, counter: 2, items: [{key: "1712DE", count: 1}, {key: "1723NE-W2", count: 4}]},
            {doorCount: 5, counter: 3, items: [{key: "1711DE", count: 1}, {key: "1712DE", count: 1}, {key: "1723NE-W2", count: 4}]},
            {doorCount: 5, counter: 4, items: [{key: "1712DE", count: 2}, {key: "1723NE-W2", count: 4}]},
            {doorCount: 5, counter: 5, items: [{key: "1711DE", count: 1}, {key: "1712DE", count: 2}, {key: "1723NE-W2", count: 4}]},
            {doorCount: 5, counter: 6, items: [{key: "1712DE", count: 3}, {key: "1723NE-W2", count: 4}]},
            {doorCount: 5, counter: 7, items: [{key: "1711DE", count: 1}, {key: "1712DE", count: 3}, {key: "1723NE-W2", count: 4}]},
            {doorCount: 5, counter: 8, items: [{key: "1712DE", count: 4}, {key: "1723NE-W2", count: 4}]},
            {doorCount: 5, counter: 9, items: [{key: "1711DE", count: 1}, {key: "1712DE", count: 4}, {key: "1723NE-W2", count: 4}]},
            {doorCount: 5, counter: 10, items: [{key: "1712DE", count: 5}, {key: "1723NE-W2", count: 4}]},
        ],
        "verschatten": [
            {doorCount: 1, counter: 1, items: [{key: "1731JE", count: 1}]},
            {doorCount: 1, counter: 2, items: [{key: "1731JE", count: 2}]},
            {doorCount: 1, counter: 3, items: [{key: "1731JE", count: 3}]},
            {doorCount: 1, counter: 4, items: [{key: "1731JE", count: 4}]},
            {doorCount: 1, counter: 5, items: [{key: "1731JE", count: 5}]},
            {doorCount: 1, counter: 6, items: [{key: "1731JE", count: 6}]},
            {doorCount: 2, counter: 1, items: [{key: "1731JE", count: 1}]},
            {doorCount: 2, counter: 2, items: [{key: "1731JE", count: 2}]},
            {doorCount: 2, counter: 3, items: [{key: "1731JE", count: 3}]},
            {doorCount: 2, counter: 4, items: [{key: "1731JE", count: 4}]},
            {doorCount: 2, counter: 5, items: [{key: "1731JE", count: 5}]},
            {doorCount: 2, counter: 6, items: [{key: "1731JE", count: 6}]},
            {doorCount: 3, counter: 1, items: [{key: "1731JE", count: 1}]},
            {doorCount: 3, counter: 2, items: [{key: "1731JE", count: 2}]},
            {doorCount: 3, counter: 3, items: [{key: "1731JE", count: 3}]},
            {doorCount: 3, counter: 4, items: [{key: "1731JE", count: 4}]},
            {doorCount: 3, counter: 5, items: [{key: "1731JE", count: 5}]},
            {doorCount: 3, counter: 6, items: [{key: "1731JE", count: 6}]},
            {doorCount: 4, counter: 1, items: [{key: "1731JE", count: 1}]},
            {doorCount: 4, counter: 2, items: [{key: "1731JE", count: 2}]},
            {doorCount: 4, counter: 3, items: [{key: "1731JE", count: 3}]},
            {doorCount: 4, counter: 4, items: [{key: "1731JE", count: 4}]},
            {doorCount: 4, counter: 5, items: [{key: "1731JE", count: 5}]},
            {doorCount: 4, counter: 6, items: [{key: "1731JE", count: 6}]},
            {doorCount: 5, counter: 1, items: [{key: "1731JE", count: 1}]},
            {doorCount: 5, counter: 2, items: [{key: "1731JE", count: 2}]},
            {doorCount: 5, counter: 3, items: [{key: "1731JE", count: 3}]},
            {doorCount: 5, counter: 4, items: [{key: "1731JE", count: 4}]},
            {doorCount: 5, counter: 5, items: [{key: "1731JE", count: 5}]},
            {doorCount: 5, counter: 6, items: [{key: "1731JE", count: 6}]},
        ],
        "heizen": [
            {doorCount: 1, counter: 1, items: [{key: "1790RTR", count: 1}]},
            {doorCount: 1, counter: 2, items: [{key: "1790RTR", count: 2}]},
            {doorCount: 1, counter: 3, items: [{key: "1790RTR", count: 3}]},
            {doorCount: 1, counter: 4, items: [{key: "1790RTR", count: 4}]},
            {doorCount: 1, counter: 5, items: [{key: "1790RTR", count: 5}]},
            {doorCount: 1, counter: 6, items: [{key: "1790RTR", count: 6}]},
            {doorCount: 2, counter: 1, items: [{key: "1790RTR", count: 1}]},
            {doorCount: 2, counter: 2, items: [{key: "1790RTR", count: 2}]},
            {doorCount: 2, counter: 3, items: [{key: "1790RTR", count: 3}]},
            {doorCount: 2, counter: 4, items: [{key: "1790RTR", count: 4}]},
            {doorCount: 2, counter: 5, items: [{key: "1790RTR", count: 5}]},
            {doorCount: 2, counter: 6, items: [{key: "1790RTR", count: 6}]},
            {doorCount: 3, counter: 1, items: [{key: "1790RTR", count: 1}]},
            {doorCount: 3, counter: 2, items: [{key: "1790RTR", count: 2}]},
            {doorCount: 3, counter: 3, items: [{key: "1790RTR", count: 3}]},
            {doorCount: 3, counter: 4, items: [{key: "1790RTR", count: 4}]},
            {doorCount: 3, counter: 5, items: [{key: "1790RTR", count: 5}]},
            {doorCount: 3, counter: 6, items: [{key: "1790RTR", count: 6}]},
            {doorCount: 4, counter: 1, items: [{key: "1790RTR", count: 1}]},
            {doorCount: 4, counter: 2, items: [{key: "1790RTR", count: 2}]},
            {doorCount: 4, counter: 3, items: [{key: "1790RTR", count: 3}]},
            {doorCount: 4, counter: 4, items: [{key: "1790RTR", count: 4}]},
            {doorCount: 4, counter: 5, items: [{key: "1790RTR", count: 5}]},
            {doorCount: 4, counter: 6, items: [{key: "1790RTR", count: 6}]},
            {doorCount: 5, counter: 1, items: [{key: "1790RTR", count: 1}]},
            {doorCount: 5, counter: 2, items: [{key: "1790RTR", count: 2}]},
            {doorCount: 5, counter: 3, items: [{key: "1790RTR", count: 3}]},
            {doorCount: 5, counter: 4, items: [{key: "1790RTR", count: 4}]},
            {doorCount: 5, counter: 5, items: [{key: "1790RTR", count: 5}]},
            {doorCount: 5, counter: 6, items: [{key: "1790RTR", count: 6}]},
        ]
    };
    const produkte = {
        "1701SE": ["scha-einsatz-1", "scha-aufsatz-1"],
        "1702SE": ["scha-einsatz-2", "scha-aufsatz-2"],
        "1731JE": ["versch-einsatz", "versch-aufsatz"],
        "1790RTR": ["heiz-einsatz", "heiz-aufsatz", "rt-ventil-1"],
        "1711DE": ["dimmer-eins-univ", "scha-aufsatz-1"],
        "1712DE": ["serien-dimmer-eins-univ", "scha-aufsatz-2"],
        "1723NE-W1": ["nebenstelle", "scha-aufsatz-1"],
        "1723NE-W2": ["nebenstelle", "scha-aufsatz-2"],
    };
    let requiredFrames = 0;
    checkList.forEach(item => {
        if(room.selectedEquipment[item.key] !== undefined && room.selectedEquipment[item.key] > 0){
            const mItems = matrix[item.matrix].filter(i => i.doorCount === room.doorCount);
            const max = matrix[item.matrix].filter(i => i.doorCount === room.doorCount && (i.counter === (['schalten','dimmen'].includes(item.matrix)?10:6)))[0];
            let required = room.selectedEquipment[item.key]; let requiredTermostat = required;
            if (item.matrix === 'verschatten') { required = room.windowCount; }
            if (item.matrix === 'heizen') {
                requiredTermostat = 0;
                if (room.selectedEquipment[item.key] > 0) { requiredTermostat += 1; }
                required = item.key === "heizen_heizkoerper" ? room.heatingCount : room.floorheatingCount;
            }
            const c = Math.floor(required / (['schalten','dimmen'].includes(item.matrix)?10:6));
            for(let i = 0; i < c; i++) {
                // eslint-disable-next-line
                max.items.forEach(mItem => {
                    produkte[mItem.key].forEach((p, pIdx) => {
                        if (pIdx < 1) {
                            const dActions = addActionForDevice(austattung_produkt[p], item, mItem.count);
                            productList.push({
                                product: austattung_produkt[p],
                                count: item.matrix === 'heizen' ? requiredTermostat : mItem.count,
                                actions: dActions.map(i => i.uuid)
                            });
                        } else if (pIdx < 2) {
                            productList.push({
                                product: austattung_produkt[p],
                                count: item.matrix === 'heizen' ? requiredTermostat : mItem.count,
                            });
                        } else {
                            productList.push({
                                product: austattung_produkt[p],
                                count: mItem.count,
                            });
                        }
                    });
                    requiredFrames += mItem.count;
                });
            }
            required -= c * (['schalten','dimmen'].includes(item.matrix)?10:6);
            if (c > 0 && item.matrix === "heizen") { requiredTermostat = 0; }
            const toPush = mItems.filter(i => i.counter === required)[0];
            if (toPush) {
                toPush.items.forEach(mItem => {
                    produkte[mItem.key].forEach((p, pIdx) => {
                        if (pIdx < 1) {
                            const dActions = addActionForDevice(austattung_produkt[p], item, mItem.count);
                            productList.push({
                                product: austattung_produkt[p],
                                count: item.matrix === 'heizen' ? requiredTermostat : mItem.count,
                                actions: dActions.map(i => i.uuid)
                            });
                        } else if (pIdx < 2) {
                            productList.push({
                                product: austattung_produkt[p],
                                count: item.matrix === 'heizen' ? requiredTermostat : mItem.count,
                            });
                        } else {
                            productList.push({
                                product: austattung_produkt[p],
                                count: mItem.count,
                            });
                        }
                    });
                    requiredFrames += mItem.count;
                });
            }
        }
    });

    const frames = [];
    if (austattung_produkt["r-5f"] !== "") { frames.push({key: "r-5f", counter: 5}); }
    if (austattung_produkt["r-4f"] !== "") { frames.push({key: "r-4f", counter: 4}); }
    if (austattung_produkt["r-3f"] !== "") { frames.push({key: "r-3f", counter: 3}); }
    if (austattung_produkt["r-2f"] !== "") { frames.push({key: "r-2f", counter: 2}); }
    if (austattung_produkt["r-1f"] !== "") { frames.push({key: "r-1f", counter: 1}); }

    const addFrames = function(oneFrame = false) {
        frames.forEach(frame => {
            const c = Math.floor(requiredFrames / room.doorCount / frame.counter);
            if (c > 0) {
                requiredFrames -= c * frame.counter * room.doorCount;
                productList.push({
                    product: austattung_produkt[frame.key],
                    count: c * room.doorCount
                });
            }
        });
        if (oneFrame && requiredFrames > 0) {
            productList.push({
                product: austattung_produkt["r-1f"],
                count: requiredFrames
            });
        }
    }
    addFrames();
    if (requiredFrames > 0) { addFrames(true); }

    return {list: productList, actions};
}




export default function getProducts(project, austattung_produkt){
    const productList = [];
    if (project.selectedEquipment["sound"] !== undefined
        || Boolean(project.selectedEquipment["sound"])
    ) {
        if (project.selectedEquipment["sound"] > 0) {
            productList.push({
                product: austattung_produkt["sound"],
                count: 1
            })
            productList.push({
                product: austattung_produkt["sound-r"],
                count: 1
            })
        }
    }
    return productList;
}



export default function getProducts(room, austattung_produkt){
    const productList = [];
    if (room.selectedEquipment["sound"] !== undefined
        || Boolean(room.selectedEquipment["sound"])
    ) {
        if (room.selectedEquipment["sound"] > 0) {
            productList.push({
                product: austattung_produkt["sound"],
                count: room.selectedEquipment["sound"]
            })
            productList.push({
                product: austattung_produkt["sound-r"],
                count: room.selectedEquipment["sound"]
            })
        }
    }

    return productList;
}

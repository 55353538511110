import React, {useEffect} from "react"
import SelectDesignComponent from "./SelectDesignComponent";
import {useTranslation} from "react-i18next";

export default function StepThreeComponent({project, updateProject, uiState, setUiState, trackingObj, jumpTo, isIFrame}){
    const {t, i18n} = useTranslation();
    const language = i18n.resolvedLanguage;
    useEffect(() => {
        if (!isIFrame) { setTimeout(() => {document.body.style.overflow = 'unset'; }, 200); }
    }, []);
    return <div>
        {/*<h2>Schalterdesign auswählen</h2>*/}
        <div className="fs-container ptb-25 bg-white" style={{marginBottom: '-25px'}}>
            <SelectDesignComponent
                project={project}
                updateProject={updateProject}
                uiState={uiState}
                updateUiState={setUiState}
                trackingObj={trackingObj}
                language={language}
                jumpTo={jumpTo}
            />
        </div>
    </div>
}

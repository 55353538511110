import React, {useEffect} from "react";
import {Tab, Tabs} from "@material-ui/core";
import controllerdesigns_de from "./../data/controllerdesign.json"
import controllerdesigns_en from "./../data/controllerdesign.json"
import controllerdesigns_es from "./../data/controllerdesign_es.json"
import controllerdesigns_it from "./../data/controllerdesign.json"
import controllerdesigns_fr from "./../data/controllerdesign.json"
import controllerdesigns_nl from "./../data/controllerdesign_nl.json"
import controllerdesigns_home_de from "./../data/controllerdesign_home.json"
import controllerdesigns_home_en from "./../data/controllerdesign_home.json"
import controllerdesigns_home_es from "./../data/controllerdesign_home_es.json"
import controllerdesigns_home_it from "./../data/controllerdesign_home.json"
import controllerdesigns_home_fr from "./../data/controllerdesign_home.json"
import controllerdesigns_home_nl from "./../data/controllerdesign_home_nl.json"
import FloorControllerComponent from "./FloorControllerComponent";
// import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import f10 from '../images/F10.svg';
import f40 from '../images/F40.svg';
import f50 from '../images/F50.svg';
import f10b from '../images/F10_blue.svg';
import f40b from '../images/F40_blue.svg';
import f50b from '../images/F50_blue.svg';
// import iIconActive from "../images/info_icon_active.svg";
import iIcon from "../images/info_icon.svg";
import FilterIcon from "@material-ui/icons/Tune";
import Button from "@material-ui/core/Button";
import iIconActive from "../images/info_icon_active.svg";
import iIconBlue from "../images/info_icon_blue.svg";
import Popover from "@material-ui/core/Popover";
import CloseIcon from "@material-ui/icons/Close";
import {useTranslation} from "react-i18next";


export default function SelectDesignComponent({project, updateProject, uiState, updateUiState, trackingObj, language, jumpTo}){
    let controllerdesigns =  controllerdesigns_de;
    if (language === 'en') { controllerdesigns = controllerdesigns_en; }
    if (language === 'es') { controllerdesigns = controllerdesigns_es; }
    if (language === 'fr') { controllerdesigns = controllerdesigns_fr; }
    if (language === 'it') { controllerdesigns = controllerdesigns_it; }
    if (language === 'nl') { controllerdesigns = controllerdesigns_nl; }
    if (project.projectType === 'home') {
        switch (language) {
            case 'en': controllerdesigns = controllerdesigns_home_en; break;
            case 'es': controllerdesigns = controllerdesigns_home_es; break;
            case 'fr': controllerdesigns = controllerdesigns_home_fr; break;
            case 'it': controllerdesigns = controllerdesigns_home_it; break;
            case 'nl': controllerdesigns = controllerdesigns_home_nl; break;
            default: controllerdesigns = controllerdesigns_home_de; break;
        }
    }
    const [infoOpened, setInfoOpened] = React.useState(false);
    const [roomsOpened, setRoomsOpened] = React.useState(false);
    const {t} = useTranslation();
    const handleChangeSerie = (event, newValue) => {
        let index0 = controllerdesigns[newValue].operatingConcepts.findIndex(d => d.isDefault);
        let index0Selected = controllerdesigns[newValue].operatingConcepts.findIndex(d => d.name === (uiState.selectedControllerOperatingConcept || ''));
        index0 = index0 > 0 ? (index0Selected > -1 ? index0Selected : index0) : 0;
        let index1 = controllerdesigns[newValue].operatingConcepts[index0].colorsTaster.findIndex(d => d.isDefault);
        index1 = index1 > 0 ? index1 : 0;
        const index2 = controllerdesigns[newValue].operatingConcepts[index0].colorsTaster[index1].colorsRahmen.findIndex(d => d.isDefault);
        const newState = {
            ...uiState,
            selectedControllerSerie: newValue,
            selectedControllerOperationConcept: index0,
            selectedControllerColorTaster: index1,
            selectedControllerColorRahmen: index2 > 0 ? index2 : 0,
            selectedControllerOperatingConcept: controllerdesigns[newValue].operatingConcepts[index0].name,
        };
        setPrevColor(index1);
        updateUiState(newState);
        if (!roomsOpened && window.innerWidth < 800) { setForAllRooms(newState); }
    };

    const handleChangeOperationConcept = (event, newValue) => {
        let index1 = controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[newValue].colorsTaster.findIndex(d => d.isDefault);
        index1 = index1 > 0 ? index1 : 0;
        const index2 = controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[newValue].colorsTaster[index1].colorsRahmen.findIndex(d => d.isDefault);

        const newState = {
            ...uiState,
            selectedControllerOperationConcept: newValue,
            selectedControllerColorTaster: index1,
            selectedControllerColorRahmen: index2 > 0 ? index2 : 0,
            selectedControllerOperatingConcept: controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[newValue].name,
        };
        setPrevColor(index1);
        updateUiState(newState);
        if (!roomsOpened && window.innerWidth < 800) { setForAllRooms(newState); }
    };
    if (window.parentIFrame) {
        if (document.activeElement) {
            document.activeElement.blur();
        }
    }


    function setNewColor1(newValue){
        const items = controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].colorsTaster;
        // console.log('newValue', newValue, items)
        if (items[newValue] !== undefined) {
            const index2 = items[newValue].colorsRahmen.findIndex(d => d.isDefault);
            let newState = {
                ...uiState,
                selectedControllerColorTaster: newValue,
                selectedControllerColorRahmen: index2 > 0 ? index2 : 0,
            };
            updateUiState(newState);
            if (!roomsOpened && window.innerWidth < 800) { setForAllRooms(newState); }
        }
    }
    const handleChangeColor1 = (event, newValue) => {
        setNewColor1(newValue);
        setPrevColor(newValue);
    };
    const handleChangeColor2 = (event, newValue) => {
        const newState = {
            ...uiState,
            selectedControllerColorRahmen: newValue,
        };
        updateUiState(newState);
        if (!roomsOpened && window.innerWidth < 800) { setForAllRooms(newState); }
    };

    function getIcon(serie, color, material, finish, isoId, type){
        var src = "https://www.projekt14.de/austausch/quick_j/";
        if (project.projectType !== 'home') { src += "JUNG_"; }
        else { src += 'home/schalter/'; }
        src += (color !== undefined) ? color : serie;
        src += ".png"
        return   src;
    }
    function getpicture(picture){
        var src = "https://www.projekt14.de/austausch/quick_j/";
        if (project.projectType === 'home') { src += 'home/schalter/'; }
        src += picture;
        src += ".png"
        return   src;
    }

    function updateFloor(floor){
        updateProject({...project, floors: project.floors.map(f => f.id !== floor.id ? f : floor)});
    }
    function getLabelIcon(n) {
        var name = controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].name;
        if (n === 'F10') {
            if (name === 'F10') { return <img src={f10b} alt="" width="26" />; }
            return <img src={f10} alt="" width="26" />;
        }
        if (n === 'F40') {
            if (name === 'F40') { return <img src={f40b} alt="" width="26" />; }
            return <img src={f40} alt="" width="26" />;
        }
        if (n === 'F50') {
            if (name === 'F50') { return <img src={f50b} alt="" width="26" />; }
            return <img src={f50} alt="" width="26" />;
        }
    }
    function generateOperationConceptTabs(operatingConcepts){
        return operatingConcepts.map((item, ix) => <Tab key={item.id} label={item.name} icon={getLabelIcon(item.name)} />)
    }
    function generateTasterColorTabs(schalterdesign, colorsTaster){
        const ispicture = controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].colorsTaster[uiState.selectedControllerColorTaster].picture;
        if (!ispicture) {
            return colorsTaster.map((item, ix) => <Tab key={"taster_" + ix} label={item.name}
                                                       icon={generateCompositeImageFromObjects(
                                                           "80px",
                                                           schalterdesign,
                                                           item,
                                                           item.colorsRahmen[0]
                                                       )}/>
            );
        } else {
            return colorsTaster.map((item, ix) => <Tab key={"taster_" + ix} label={item.name}
                                                       icon={generateCompositeImageFromObjects("80px", null, null, null, item.picture)}
                />
            );
        }

    }
    function generateRahmenColorTabs(schalterdesign, colorsRahmen){
        return colorsRahmen.map((item, ix) => <Tab key={"rahmen_" + ix} label={item.name} icon={<img src={getIcon(schalterdesign.id, item.colorId, item.materialId, item.finishId, item.isoId, "r")} alt="" style={{width: "80px"}}/>} />)
    }
    function changeTaster(direction, length) {
        let newColor = -1;
        switch (direction) {
            case 'prev':
                if (uiState.selectedControllerColorTaster === 0) { return false; }
                newColor = uiState.selectedControllerColorTaster - 1;
                break;
            case 'next':
                if (uiState.selectedControllerColorTaster === length - 1) { return false; }
                newColor = uiState.selectedControllerColorTaster + 1;
                break;
            default: return false;
        }
        setNewColor1(newColor);
        setPrevColor(newColor);
    }
    function generateCompositeImageWithRahmenColorTabs(controllerdesign, isPhone = false){
        // console.log(controllerdesign, uiState.selectedControllerSerie, uiState.selectedControllerOperationConcept, uiState.selectedControllerColorTaster)
        const ispicture = controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].colorsTaster[uiState.selectedControllerColorTaster].picture;
        return <div className="composite-image-container">
            <div style={{minWidth:"unset", width: "auto", padding: "0 50px 0 120px"}} className={"composite-container"}>
                <div className={"arrow-container"}>
                    <button
                        className={uiState.selectedControllerColorTaster > 0 ? "arrow-left button" : "hidden"}
                            onClick={() => {changeTaster('prev', controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].colorsTaster.length)}}
                    >
                        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24"
                             aria-hidden="true">
                            <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"></path>
                        </svg>
                    </button>
                </div>
                {ispicture ?
                    <div className={"compositeImage"} style={{minWidth: "260px", minHeight: "260px", overflow: "hidden"}}>
                        {generateCompositepicture(ispicture)}
                    </div>
                    : <div className={"compositeImage"} style={{minWidth: "300px"}}>
                        {generateCompositeImage("100%", uiState.selectedControllerSerie, uiState.selectedControllerOperationConcept, uiState.selectedControllerColorTaster, uiState.selectedControllerColorRahmen)}
                    </div>
                }
                <div className={"arrow-container textLeft"}>
                    <button
                        className={uiState.selectedControllerColorTaster < controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].colorsTaster.length - 1 ? "arrow-right button" : "hidden"}
                        onClick={() => {changeTaster('next', controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].colorsTaster.length)}}
                    >
                        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24"
                             aria-hidden="true">
                            <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"></path>
                        </svg>
                    </button>
                </div>
                <a href={"/"} onClick={(event) => {setInfoOpened(!infoOpened); event.preventDefault(); return false;}} className={"phoneShow phone-tooltip"}>
                    <img src={iIcon} width="32" height="32" style={{top: "10px"}} alt={"info"} />
                </a>
            </div>
            <div className={"description phoneNoShow"}>{getInfoBoxselectedControllerSerie(true)}</div>
            {infoOpened ? <div className={"description mt-m-30"}>{getInfoBoxselectedControllerSerie(true)}</div> : null}
        </div>;
    }
    function generateCompositeImage(width, selectedControllerSerie, selectedControllerOperationConcept, selectedControllerColor1, selectedControllerColor2){
        return generateCompositeImageFromObjects(
            width,
            controllerdesigns[selectedControllerSerie],
            controllerdesigns[selectedControllerSerie].operatingConcepts[selectedControllerOperationConcept].colorsTaster[selectedControllerColor1],
            controllerdesigns[selectedControllerSerie].operatingConcepts[selectedControllerOperationConcept].colorsTaster[selectedControllerColor1].colorsRahmen[selectedControllerColor2],
            controllerdesigns[selectedControllerSerie].operatingConcepts[selectedControllerOperationConcept].colorsTaster[selectedControllerColor1].picture
        );
    }
    function generateCompositepicture(picture){
        return generateCompositeImageFromObjects(null, null, null, null, picture);
    }
    function generateCompositeImageFromObjects(width, selectedControllerSerie, selectedControllerColor1, selectedControllerColor2, picture = null){
        if (!picture && !selectedControllerColor1.picture) {
            return <div className={"imageTasterAndFrame"} style={{display: "inline-block", width: width, position: "relative"}}>
                <div className={"imageTaster compositeImageBackground"}></div>
                <img className={"imageFrame"} src={getIcon(selectedControllerSerie.id, selectedControllerColor2.colorId, selectedControllerColor2.materialId, selectedControllerColor2.finishId, selectedControllerColor2.isoId, "r2")} style={{width: "100%", verticalAlign: "bottom"}} alt={""}/>
                <img className={"imageTaster"} src={getIcon(selectedControllerSerie.id, selectedControllerColor1.colorId, selectedControllerColor1.materialId, selectedControllerColor1.finishId, selectedControllerColor1.isoId, "t")} style={{width: "100%", position: "absolute", top: 0, left: 0}} alt={""}/>
                {selectedControllerColor1.designCover !== undefined ? <img className={"imageTaster"} src={getIcon(selectedControllerSerie.id, selectedControllerColor1.designCover, selectedControllerColor2.materialId, selectedControllerColor2.finishId, selectedControllerColor2.isoId, "designCover")} style={{width: "100%", position: "absolute", top: 0, left: 0}} alt={""}/> : null}
            </div>
        } else {
            if (!picture) { picture = selectedControllerColor1.picture; }
            if (width === '50px') {
                const pw = '60px';
                return <div className={"imageTasterAndFrame"} style={{display: "inline-block", width: pw, position: "relative", height: pw}}>
                    <img className={"imageFrame"} src={getpicture(picture)} style={{width: pw}} alt={""}/>
                </div>;
            }
            return <div className={"imageTasterAndFrame"} style={{display: "inline-block", width: width, position: "relative", height: width !== "80px" ? "unset" : width}}>
                <img className={"imageFrame"}
                     src={getpicture(picture)}
                     style={{width: width !== "80px" ? "380px" : "110px", marginLeft: width !== "80px" ? "-60px" : "-15px", marginTop: width !== "80px" ? "-75px" : "-15px"}} alt={""}/>
            </div>;
        }
    }

    // const HtmlTooltip = withStyles((theme) => ({
    //     tooltip: {
    //         backgroundColor: "var(--grau2)",
    //         color: "var(--schwarz)",
    //         fontSize: "14px",
    //         border: '1px solid #dadde9',
    //         maxWidth: '250px',
    //         minWidth: '150px',
    //         boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    //         fontWeight: 'normal !important'
    //     },
    // }))(Tooltip);
    // const InfoOutlinedIconCustom = withStyles((theme) => ({
    //     root: {
    //         color: "var(--grau3)",
    //         fontSize: "18px",
    //         verticalAlign: "middle",
    //         paddingLeft: "5px"
    //     },
    // }))(InfoOutlinedIcon);
    // const HtmlTooltipDesign = withStyles((theme) => ({
    //     tooltip: {
    //         backgroundColor: "var(--weiss)",
    //         color: "var(--textColor)",
    //         fontSize: "14px",
    //         border: '1px solid #dadde9',
    //         maxWidth: '400px',
    //         minWidth: '150px',
    //         padding: "0px 7px",
    //         boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    //     },
    // }))(Tooltip);
    // const InfoOutlinedIconCustomActive = withStyles((theme) => ({
    //     root: {
    //         color: "var(--captionColor)",
    //         fontSize: "18px",
    //         verticalAlign: "sub",
    //         alignContent: "right",
    //         paddingLeft: "5px"
    //     },
    // }))(InfoOutlinedIcon);
    function getConceptInfoText() {
        var txt = '';
        switch (controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].name) {
            case 'F10': txt = '' + t('app.step.controllerProgram.operatingConcept.F10'); break;
            case 'F40': txt = t('app.step.controllerProgram.operatingConcept.F40'); break;
            case 'F50': txt = t('app.step.controllerProgram.operatingConcept.F50'); break;
            case 'LS Touch': txt = t('app.step.controllerProgram.operatingConcept.LS Touch'); break;
            default: txt = ''; break;
        }
        return txt;
    }
    function getInfoBoxselectedControllerSerie(justText = false){
        const toolTipText = <div className={justText ? "noTextTransform" : ""}>
            <div className={"row design-info"}>
                <div className={"col-2 info-cat"}>{t('app.step.controllerProgram.switch.program')}</div>
                <div className={"col-6 info-txt"}>{controllerdesigns[uiState.selectedControllerSerie].name}</div>
            </div>
            {project.projectType === 'knx' ? <div className={"row design-info"}>
                <div className={"col-2 info-cat"}>{t('app.step.controllerProgram.operatingConcept')}
                    {/*<TooltipWithButton props={{title: t('app.step.controllerProgram.operatingConcept'), message: getConceptInfoText()}} />*/}
                </div>
                <div className={"col-6 info-txt"}>{controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].name}</div>
            </div> : null}
            <div className={"row design-info"}>
                <div className={"col-2 info-cat"}>{t('app.step.controllerProgram.button')}
                    {/*<TooltipWithButton props={{title: 'Taster', message: project.projectType !== 'home'*/}
                    {/*        ? t('app.step.controllerProgram.button.info')*/}
                    {/*        : t('app.step.controllerProgram.button.info.home')*/}
                    {/*    }} />*/}
                </div>
                <div className={"col-6 info-txt"}>
                    {controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].colorsTaster[uiState.selectedControllerColorTaster].colorName}
                    {controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].colorsTaster[uiState.selectedControllerColorTaster].materialName !== undefined ? ', ' + controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].colorsTaster[uiState.selectedControllerColorTaster].materialName : null}
                    {controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].colorsTaster[uiState.selectedControllerColorTaster].finishName !== undefined ? ', ' + controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].colorsTaster[uiState.selectedControllerColorTaster].finishName : null}
                </div>
            </div>
            {/*<div className={"row design-info"}>*/}
            {/*    <div className={"col-2 info-cat"}>{t('app.step.controllerProgram.frame')}</div>*/}
            {/*    <div className={"col-6 info-txt"}>*/}
            {/*        {controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].colorsTaster[uiState.selectedControllerColorTaster].colorsRahmen[uiState.selectedControllerColorRahmen].colorName}*/}
            {/*        {controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].colorsTaster[uiState.selectedControllerColorTaster].colorsRahmen[uiState.selectedControllerColorRahmen].materialName !== undefined ? ', ' + controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].colorsTaster[uiState.selectedControllerColorTaster].colorsRahmen[uiState.selectedControllerColorRahmen].materialName : null}*/}
            {/*        {controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].colorsTaster[uiState.selectedControllerColorTaster].colorsRahmen[uiState.selectedControllerColorRahmen].finishName !== undefined ? ', ' + controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].colorsTaster[uiState.selectedControllerColorTaster].colorsRahmen[uiState.selectedControllerColorRahmen].finishName : null}*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>;
        if (justText) { return toolTipText; }
        return <TooltipWithButton props={{title: '', message: toolTipText}} />;
    }
    function setForAllRooms(newState) {
        const currentDesign = {
            selectedControllerSerie: controllerdesigns[newState.selectedControllerSerie],
            selectedControllerOperationConcept: controllerdesigns[newState.selectedControllerSerie].operatingConcepts[newState.selectedControllerOperationConcept],
            selectedControllerColor1: controllerdesigns[newState.selectedControllerSerie].operatingConcepts[newState.selectedControllerOperationConcept].colorsTaster[newState.selectedControllerColorTaster],
            selectedControllerColor2: controllerdesigns[newState.selectedControllerSerie].operatingConcepts[newState.selectedControllerOperationConcept].colorsTaster[newState.selectedControllerColorTaster].colorsRahmen[newState.selectedControllerColorRahmen],
            picture: controllerdesigns[newState.selectedControllerSerie].operatingConcepts[newState.selectedControllerOperationConcept].colorsTaster[newState.selectedControllerColorTaster].picture
        };
        let newFloors = [];
        project.floors.forEach((floor, idx) => {
            newFloors.push({
                ...floor,
                rooms: floor.rooms.map(r => {
                    return {
                        ...r,
                        selectedDesign: currentDesign,
                        manual: true
                    };
                })
            });
        });
        updateProject({...project, floors: newFloors});
    }
    function TooltipWithButton(props) {
        const el = document.createElement('a');
        document.body.appendChild(el);
        const [anchorEl, setAnchorEl] = React.useState(null);
        const [opened, setOpened] = React.useState(false);
        const id = 'popover_' + ((Math.random() * 1000) + 1);
        // const open = Boolean(anchorEl);
        const params = props.props;
        // setTimeout(() => {if (!opened) {document.body.style.overflow = 'unset';}}, 100);
        const handleClick = (event) => {
            event.preventDefault(); event.stopPropagation();
            setAnchorEl(event.currentTarget); setOpened(true);
            // document.body.style.overflow = 'hidden';
            return false;
        };
        const handleClose = (event) => {
            event.preventDefault(); event.stopPropagation();
            setOpened(false);
            // document.body.style.overflow = 'unset';
            return false;
        };
        return <>
            <Button aria-describedby={id} variant="contained" onClick={handleClick} className={"button-without-style" + (!params.active ? (!params.noActive ? ' inactive' : '') : '') + (params.absolutePosition ? ' absolutePosition' : '')}>
                {params.active
                    ? <span className="info-icon">
                        <img src={iIconActive} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"paramActive"}/>
                    </span>
                    : (!params.noActive
                            ? <span className="info-icon">
                            <img src={iIconBlue} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"hideOnHover"}/>
                            <img src={iIconActive} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"showOnHover"}/>
                        </span>
                            : <span>
                            <img src={iIconBlue} width="32" height="32" style={{marginTop: "10px"}} alt={"info"} className={"paramNoActive"}/>
                        </span>
                    )
                }
            </Button>
            {'parentIFrame' in window
            ? <Popover id={id} open={true} anchorEl={anchorEl} onClose={handleClose}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                     }}
                     style={{maxWidth: window.innerWidth > 800 ? '75%' : '100%', visibility: opened ? 'visible' : 'hidden'}}
            >
                <div className={"popoverContainer"} style={{color: 'var(--blueColor)', padding: '15px', position: 'relative'}}>
                    <a href={"/"} onClick={handleClose} style={{position: 'absolute', top: '5px', right: '5px', color: 'var(--blueColor)'}}><CloseIcon /></a>
                    <p style={{fontWeight: 'bold'}}>{params.title}</p>
                    <p dangerouslySetInnerHTML={{__html: params.message}}></p>
                </div>
            </Popover>
            : <Popover id={id} open={opened} anchorEl={anchorEl} onClose={handleClose}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                     }}
                     style={{maxWidth: window.innerWidth > 800 ? '75%' : '100%', visibility: opened ? 'visible' : 'hidden'}}
            >
                <div className={"popoverContainer"} style={{color: 'var(--blueColor)', padding: '15px', position: 'relative'}}>
                    <a href={"/"} onClick={handleClose} style={{position: 'absolute', top: '5px', right: '5px', color: 'var(--blueColor)'}}><CloseIcon /></a>
                    <p style={{fontWeight: 'bold'}}>{params.title}</p>
                    <p dangerouslySetInnerHTML={{__html: params.message}}></p>
                </div>
            </Popover>}
        </>;
    }
    const [prevColor, setPrevColor] = React.useState(-1);
    function handleMouseEnter(event, idx) {
        event.preventDefault(); event.stopPropagation();
        if (uiState.selectedControllerColorTaster !== idx) {
            if (prevColor < 0) { setPrevColor(uiState.selectedControllerColorTaster); }
            setNewColor1(idx);
        }
        return false;
    }
    function handleMouseLeave(event, idx) {
        event.preventDefault(); event.stopPropagation();
        if (uiState.selectedControllerColorTaster !== idx) { setNewColor1(prevColor); }
        return false;
    }
    function handleMouseClick(event, idx) {
        event.preventDefault(); event.stopPropagation();
        setPrevColor(idx); setNewColor1(idx);
        return false;
    }
    function ColorItem(props) {
        const color = props.color;
        const idx = props.idx;
        return <div key={color.colorHex} className={"color" + (prevColor === idx ? " active":"")} style={{background: "#" + color.colorHex}}
                    onMouseEnter={event => handleMouseEnter(event, idx)}
                    onMouseLeave={event => handleMouseLeave(event, idx)}
                    onClick={event => handleMouseClick(event, idx)}
        ></div>
    }
    function generateColors(list) {
        const l = [];
        list.map((item, idx) => {
            l.push(<ColorItem color={item} idx={idx} key={idx} />);
        });
        return l;
    }
    return <>
        <div className={"padding-container"}>
            <div className="section-title">{t('app.step.controllerProgram.title')}</div>
            <div className="section-text">{t('app.step.controllerProgram.description')}
                {/*<TooltipWithButton props={{noActive: true, title: t('app.step.controllerProgram.info.title'), message: t('app.step.controllerProgram.info')}} />*/}
            </div>
        </div>
        <div className="gray-border wMargin" style={{paddingLeft: '0', marginBottom: '0'}}>
            <div className={"clearfix"} style={{marginTop: '15px', paddingLeft: '25px'}}>
                <div className={"SerienSelectBox"}>
                    <Tabs
                        value={uiState.selectedControllerSerie}
                        onChange={handleChangeSerie}
                        variant="scrollable"
                        scrollButtons="on"
                        indicatorColor="primary"
                        textColor="primary"

                    >
                        {controllerdesigns.map(item => <Tab key={item.id} label={item.name} icon={<img src={getIcon(item.id)} alt="" style={{width: "60px"}}/>} />)}
                    </Tabs>
                </div>
            </div>
            <div className={"composite-with-rahmen phoneNoShow"}>
                {generateCompositeImageWithRahmenColorTabs(controllerdesigns[uiState.selectedControllerSerie])}
            </div>
            <div className={"composite-with-rahmen phoneShow"}>
                {generateCompositeImageWithRahmenColorTabs(controllerdesigns[uiState.selectedControllerSerie], true)}
            </div>
            {(controllerdesigns[uiState.selectedControllerSerie].id).indexOf('JUNG-LC') >= 0 ?
                <div className={"clearfix"}>
                    <div className={""} style={{marginTop: '30px'}}>
                        <div className="section-title phoneNoPadding" style={{paddingLeft: '55px'}}>{t('app.step.controllerProgram.colorSelectionGrid')}</div>
                        <div style={{marginTop: "-30px"}} className={"tasterNoTitle tasterColors"}>
                            <div className={"colors-wrapper"} onMouseLeave={event => {if (uiState.selectedControllerColorTaster !== prevColor && prevColor >= 0) {setNewColor1(prevColor);}}}>
                                {generateColors(controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].colorsTaster)}
                            </div>
                        </div>
                    </div>
                </div>
                : null
            }
            <div className={"clearfix" + ((controllerdesigns[uiState.selectedControllerSerie].id).indexOf('JUNG-LC') >= 0 ? " phoneHide" : '')}>
                <div style={{marginTop: "5px"}} className={"tasterNoTitle tasterColors"}>
                {project.projectType === 'knx' ? <div className="section-title" style={{paddingLeft: '55px'}}>{t('app.step.controllerProgram.colorSelection')}</div> : null}
                <Tabs
                    value={uiState.selectedControllerColorTaster}
                    onChange={handleChangeColor1}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    {generateTasterColorTabs(
                        controllerdesigns[uiState.selectedControllerSerie],
                        controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].colorsTaster
                    )}
                </Tabs>
            </div>
            </div>
            <div className={"clearfix"}>
                <div className={""} style={{float: "none", width: "100%", marginBottom: "15px"}}>
                    { controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].colorsTaster[uiState.selectedControllerColorTaster].colorsRahmen.length < 2 ? null :
                    <div style={{marginTop: "45px"}}>
                        <div>Rahmen</div>
                        <Tabs
                            value={uiState.selectedControllerColorRahmen}
                            onChange={handleChangeColor2}
                            variant="scrollable"
                            scrollButtons="on"
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            {generateRahmenColorTabs(
                                controllerdesigns[uiState.selectedControllerSerie],
                                controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].colorsTaster[uiState.selectedControllerColorTaster].colorsRahmen
                            )}
                        </Tabs>
                    </div> }
                </div>
            </div>
        </div>
        <div className={"padding-container background-grau zuweisung"} style={{paddingTop: "15px", paddingBottom: '32px'}}>
            <div className={"section-title"}>{t('app.step.controllerProgram.assignSelection.title')}</div>
            <div className={"section-text border-bottom pb-15" + (!roomsOpened ? ' noBorder' : '')} style={{marginBottom: '-20px', flexDirection: 'column'}}>
                {project.projectType !== 'konventionell'
                    ? t('app.step.controllerProgram.assignSelection.description')
                    : t('app.step.controllerProgram.assignSelection.description.konventionell')
                }
                <div className={"customOpener phoneShow" + (roomsOpened ? ' opened' : '')} style={{width: '100%', margin: '15px auto'}}>
                    <a href="/" onClick={(event) => {event.preventDefault(); setRoomsOpened(!roomsOpened); return false;} }>
                        <FilterIcon />
                        <div>{roomsOpened ? "Schließen" : "Bearbeiten"}</div>
                    </a>
                </div>
            </div>
            {roomsOpened || window.innerWidth >= 800 ? project.floors.map((floor, idx) =>
                <FloorControllerComponent
                    key={floor.id}
                    floor={floor}
                    data={controllerdesigns}
                    updateFloor={updateFloor}
                    generateCompositeImage={generateCompositeImageFromObjects}
                    currentDesign={
                        {
                            selectedControllerSerie: controllerdesigns[uiState.selectedControllerSerie],
                            selectedControllerOperationConcept: controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept],
                            selectedControllerColor1: controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].colorsTaster[uiState.selectedControllerColorTaster],
                            selectedControllerColor2: controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].colorsTaster[uiState.selectedControllerColorTaster].colorsRahmen[uiState.selectedControllerColorRahmen],
                            picture: controllerdesigns[uiState.selectedControllerSerie].operatingConcepts[uiState.selectedControllerOperationConcept].colorsTaster[uiState.selectedControllerColorTaster].picture
                        }
                    }
                    opened={idx===0}
                    jumpTo={jumpTo}
                />
            ) : null}
        </div>
    </>

}


export class GenerateImagePath{

    static getPath(id, prefix = ""){

        var src = "https://www.projekt14.de/austausch/quick_j/";
        src += prefix;
        src += id;
        src += ".png"
        return   src;
    }
}

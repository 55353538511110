import React, {useEffect} from "react"
import SelectConfLevelComponent from "./SelectConfLevelComponent";
import EquimentSelectComponent from "./EquimentSelectComponent";
import Button from "@material-ui/core/Button";
import iIconActive from "../images/info_icon_active.svg";
import iIconBlue from "../images/info_icon_blue.svg";
import Popover from "@material-ui/core/Popover";
import CloseIcon from "@material-ui/icons/Close";
import {useTranslation} from "react-i18next";

export default function StepTwoComponent({project, equipmentData, updateProject , updateProjectWithNewLevel, trackingObj, jumpTo, isIFrame}){
    const [customPlan, setCustomPlan] = React.useState(false);
    const {t, i18n} = useTranslation();
    const language = i18n.resolvedLanguage;
    useEffect(() => {
        if (!isIFrame) { setTimeout(() => {document.body.style.overflow = 'unset'; }, 200); }
    }, []);
    function setConfLevel(e, level){
        e.stopPropagation();
        updateProjectWithNewLevel({...project, selectedLevel: level});
    }
    function updateTracking() {
        let selectedEquipments = [];
        project.floors.forEach(floor => {
            floor.rooms.forEach(room => {
                equipmentData.forEach(ed => {
                    ed.options.forEach(opt => {
                        const found = selectedEquipments.filter(i => i.name === opt.name)[0];
                        let eq;
                        if (found) {
                            eq = Object.assign(found, {count: found.count + parseInt(room.selectedEquipment[opt.id] || 0,10)});
                        } else if (room.selectedEquipment[opt.id]) {
                            eq = {name: opt.name, count: parseInt(room.selectedEquipment[opt.id],10)};
                        }
                        if (eq) { selectedEquipments = [...selectedEquipments.filter(i => i.name !== opt.name), eq]; }
                    });
                });
            });
        });
    }
    updateTracking();
    function TooltipWithButton(props) {
        const el = document.createElement('a');
        document.body.appendChild(el);
        const [anchorEl, setAnchorEl] = React.useState(null);
        const [opened, setOpened] = React.useState(false);
        const id = 'popover_' + ((Math.random() * 1000) + 1);
        const params = props.props;
        const handleClick = (event) => {
            event.preventDefault(); event.stopPropagation();
            setAnchorEl(event.currentTarget); setOpened(true);
            return false;
        };
        const handleClose = (event) => {
            event.preventDefault(); event.stopPropagation();
            setOpened(false);
            return false;
        };
        return <>
            <Button aria-describedby={id} variant="contained" onClick={handleClick} className={"button-without-style" + (!params.active ? (!params.noActive ? ' inactive' : '') : '') + (params.absolutePosition ? ' absolutePosition' : '')}>
                {params.active
                    ? <span className="info-icon">
                        <img src={iIconActive} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"paramActive"}/>
                    </span>
                    : (!params.noActive
                            ? <span className="info-icon">
                            <img src={iIconBlue} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"hideOnHover"}/>
                            <img src={iIconActive} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"showOnHover"}/>
                        </span>
                            : <span>
                            <img src={iIconBlue} width="32" height="32" style={{marginTop: "10px"}} alt={"info"} className={"paramNoActive"}/>
                        </span>
                    )
                }
            </Button>
            {'parentIFrame' in window
            ? <Popover id={id} open={true} anchorEl={anchorEl} onClose={handleClose}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                     }}
                     style={{maxWidth: window.innerWidth > 800 ? '75%' : '100%', visibility: opened ? 'visible' : 'hidden'}}
            >
                <div className={"popoverContainer"} style={{color: 'var(--blueColor)', padding: '15px', position: 'relative'}}>
                    <a href={"/"} onClick={handleClose} style={{position: 'absolute', top: '5px', right: '5px', color: 'var(--blueColor)'}}><CloseIcon /></a>
                    <p style={{fontWeight: 'bold'}}>{params.title}</p>
                    <p dangerouslySetInnerHTML={{__html: params.message}}></p>
                </div>
            </Popover>
            : <Popover id={id} open={opened} anchorEl={anchorEl} onClose={handleClose}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                     }}
                     style={{maxWidth: window.innerWidth > 800 ? '75%' : '100%', visibility: opened ? 'visible' : 'hidden'}}
            >
                <div className={"popoverContainer"} style={{color: 'var(--blueColor)', padding: '15px', position: 'relative'}}>
                    <a href={"/"} onClick={handleClose} style={{position: 'absolute', top: '5px', right: '5px', color: 'var(--blueColor)'}}><CloseIcon /></a>
                    <p style={{fontWeight: 'bold'}}>{params.title}</p>
                    <p dangerouslySetInnerHTML={{__html: params.message}}></p>
                </div>
            </Popover>}
        </>;
    }

    return <div>
        {!['nl'].includes(language) && (project.projectType !== 'home' || (project.projectType === 'home' && project.subProjectType !== 'umbau')) ? <div className="fs-container ptb-25 mt-15">
            <div className="padding-container">
                <div className="section-title">{t('app.step.equipmentScope.title')} {
                    project.projectType !== 'konventionell' || (project.projectType === 'konventionell' && project.subProjectType !== 'umbau')
                        ? <TooltipWithButton props={{noActive: true, title: '', message: t('app.step.equipmentScope.info')}} />
                        : null
                }</div>
                <div className="section-text mb-15">{t('app.step.equipmentScope.description')}</div>
                {!['nl'].includes(language) && (project.projectType !== 'konventionell' || (project.projectType === 'konventionell' && project.subProjectType !== 'umbau')) ? <SelectConfLevelComponent selectedLevel={project.selectedLevel} setLevel={setConfLevel}/> : null}
            </div>
        </div> : null}
        {/*<div className="fs-container mt-15 ptb-25">*/}
        {/*    <div className="padding-container">*/}
        {/*        <div className="section-title"></div>*/}
        {/*        <div className="section-text">Den vordefinierte Ausstattungsumfang der Elektroinstallation können Sie im Verlauf der Konfiguration beliebig individuell anpassen:</div>*/}
        {/*    </div>*/}
        {/*</div>*/}
        { equipmentData.map( (item, idx) => <EquimentSelectComponent project={project} equipmentData={item} updateProject={updateProject} key={item.id} opened={idx===0 && window.innerWidth > 800} setLevel={setConfLevel} starsClickable={false} equipmentCustomPlan={customPlan} setEquipmentCustomPlan={setCustomPlan} updateTracking={updateTracking} jumpTo={jumpTo}/>)}
    </div>
}

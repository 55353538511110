

export default function getProducts(room, austattung_produkt){

    const productList = [];
    let frameCount = 0;

    if(
        room.selectedEquipment["steckdosen"] !== undefined
        && room.selectedEquipment["steckdosen"] > 0
        && austattung_produkt["steckdosen"] !== undefined
    ){
        productList.push({
            product: austattung_produkt["steckdosen"],
            count: room.selectedEquipment["steckdosen"]
        })
    }
    if(
        room.selectedEquipment["steckdosen-eneg"] !== undefined
        && room.selectedEquipment["steckdosen-eneg"] > 0
        && austattung_produkt["steckdosen-eneg"] !== undefined
    ){
        productList.push({
            product: austattung_produkt["steckdosen-eneg"],
            count: room.selectedEquipment["steckdosen-eneg"]
        })
        frameCount += room.selectedEquipment["steckdosen-eneg"];
    }
    if(
        room.selectedEquipment["steckdosen-home"] !== undefined
        && room.selectedEquipment["steckdosen-home"] > 0
        && austattung_produkt["steckdosen-home"] !== undefined
    ){
        productList.push({
            product: austattung_produkt["steckdosen-home"],
            count: room.selectedEquipment["steckdosen-home"]
        })
        frameCount += room.selectedEquipment["steckdosen-home"];
    }
    const frames = [
        {key: "r-5f", counter: 5},
        {key: "r-4f", counter: 4},
        {key: "r-3f", counter: 3},
        {key: "r-2f", counter: 2},
        {key: "r-1f", counter: 1},
    ];
    frames.forEach(frame => {
        const c = Math.floor(frameCount / (room.doorCount > 0 ? room.doorCount : 1) / frame.counter);
        if (c > 0) {
            frameCount -= c * frame.counter * (room.doorCount > 0 ? room.doorCount : 1);
            productList.push({
                product: austattung_produkt[frame.key],
                count: c * (room.doorCount > 0 ? room.doorCount : 1)
            });

        }
    });
    return productList;
}



export default function getProducts(room, austattung_produkt){

    const productList = [];

    if(
        room.selectedEquipment["telefon_datenanschluss"] !== undefined
        && room.selectedEquipment["telefon_datenanschluss"] > 0
        && austattung_produkt["uae"] !== undefined
        && austattung_produkt["uae-zentralstueck"] !== undefined
    ){
        productList.push({
            product: austattung_produkt["uae"],
            count: room.selectedEquipment["telefon_datenanschluss"]
        });
        productList.push({
            product: austattung_produkt["uae-zentralstueck"],
            count: room.selectedEquipment["telefon_datenanschluss"]
        });
    }

    return productList;
}

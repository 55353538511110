import {v4 as uuidv4} from 'uuid';

export default function getProducts(room, austattung_produkt){

    const productList = [];
    const actions = [];

    let fullCount = 0;

    if(
        room.selectedEquipment["melden_kontakt_fenster"] !== undefined
        && Boolean(room.selectedEquipment["melden_kontakt_fenster"])
        && austattung_produkt["binaereingang"] !== undefined
        && room.windowCount > 0
    ){
        fullCount += room.windowCount;
        actions.push({type: 'alert_touch', uuid: uuidv4(), device: austattung_produkt["binaereingang"], addedIn: 'AddBinaereingang'});
    }
    if(
        room.selectedEquipment["melden_kontakt_tuer"] !== undefined
        && Boolean(room.selectedEquipment["melden_kontakt_tuer"])
        && austattung_produkt["binaereingang"] !== undefined
        && room.doorCount > 0
    ){
        fullCount++;
        actions.push({type: 'alert_touch', uuid: uuidv4(), device: austattung_produkt["binaereingang"], addedIn: 'AddBinaereingang'});
    }
    if(
        room.selectedEquipment["melden_leckage"] !== undefined
        && Boolean(room.selectedEquipment["melden_leckage"])
        && austattung_produkt["binaereingang"] !== undefined
    ){
        fullCount++;
        actions.push({type: 'alert_leakage', uuid: uuidv4(), device: austattung_produkt["binaereingang"], addedIn: 'AddBinaereingang'});
    }
    if(fullCount > 0){
        productList.push({
            product: austattung_produkt["binaereingang"],
            count: Math.ceil(fullCount / 6)
        })
    }

    return {list: productList, actions: actions};
}

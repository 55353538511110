import React from "react"
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import logoWerbung from '../images/logoWerbung.svg';
import {withStyles} from "@material-ui/core/index";
import Button from "@material-ui/core/Button/index";

export default function StepHomeComponent({project, startProject}){
    const ColorButton2 = withStyles((theme) => ({
        root: {
            color: "var(--weiss)",
            backgroundColor: "transparent",
            '&:hover': {
                backgroundColor:"transparent",
            },
            borderRadius: 0,
            border: "1px solid white",
            padding: "0 25px",
            width: "143px",
            lineHeight: "45px",
            fontSize: "16px",
            textAlign: "center",
            margin: "0",
            textTransform: "inherit",
            height: "34px",
            cursor: "pointer"
        },
    }))(Button);
    const ColorButton4 = withStyles((theme) => ({
        root: {
            color: "var(--weiss)",
            backgroundColor: "var(--darkblue)",
            '&:hover': {
                backgroundColor:"var(--darkblue)",
            },
            cursor: "pointer",
            height: "45px",
            padding: "45px 15px",
            fontSize: "16px",
            marginTop: "0",
            textAlign: "left",
            lineHeight: "1.3",
            borderRadius: "0",
            textTransform: "inherit",
            width: "49%",
            marginRight: "0.5%",
            marginLeft: "0.5%"
        },
    }))(Button);

    return <div>
        <div className="headerBox">
            <div className="box blue left" style={{float: "left"}}>
                <h1>Gebäudesteuerung in wenigen Schritten planen – von konventionell bis smart.</h1>
                <p style={{marginTop: "62px", marginBottom: "10px", lineHeight: "22px"}}>Mit dem Feelsmart.-Schnellkonfigurator können Sie ihr Gebäude einfach mit komfortablen und energiesparenden Funktionen und Schalterserien ausstatten und die Kosten und Förderpotenziale verschiedener Systeme anhand der Produktliste vergleichen.</p>
            </div>
            <div className="box darkblue right" style={{marginLeft: "20px"}}>
                <h2 style={{paddingTop: "2px"}}>Projekt-Code?</h2>
                <p style={{lineHeight: "22px", marginBottom: "42px"}}>Hier können Sie den Projekt-Code von einem bereits geplanten Projekt eingeben, um die Projektliste zu öffnen.</p>
                <div className="inputBoxContainer">
                    <input type="text" name="projectCode" className="inputText"/>
                    <ColorButton2>Laden</ColorButton2>
                </div>
            </div>
        </div>
        <div className="clearfix"></div>
        <div className="headerBox second">
            <div className="box left">
                <Stepper activeStep={0} alternativeLabel style={{marginLeft: "40px"}} orientation="vertical">
                    <Step key="1">
                        <StepLabel>Gebäudestruktur anlegen</StepLabel>
                    </Step>
                    <Step key="2">
                        <StepLabel>Ausstattungsumfang definieren</StepLabel>
                    </Step>
                    <Step key="3">
                        <StepLabel>Schalterdesign auswählen</StepLabel>
                    </Step>
                    <Step key="4">
                        <StepLabel>Weitere Ausstattung der Gebäudesteuerung wählen</StepLabel>
                    </Step>
                    <Step key="5">
                        <StepLabel>Projektliste anzeigen lassen</StepLabel>
                    </Step>
                </Stepper>
            </div>
            <div className="buttonsHolder" style={{paddingTop: "45px"}}>
                <ColorButton4 onClick={e => startProject("knx")}>
                    <span className={"headerMenuItem"}>Neubau starten</span>
                </ColorButton4>
                <ColorButton4 onClick={e => startProject("enet")}>
                    <span className={"headerMenuItem"}>Modernisierung starten</span>
                </ColorButton4>
            </div>
        </div>
        <div className="clearfix"></div>
        <div className="box werbung">
            <div className="text"><strong>Jetzt doppelt sparen –</strong><br/>Energiekosten senken und von<br/>finanzieller Förderung profitieren.</div>
            <img src={logoWerbung} alt="" style={{marginLeft: "45px"}} />
        </div>
        <div className="clearfix"></div>
        <div className="box lightGreen">
            <p><strong>BEG-Förderung – das Wichtigste auf einen Blick</strong></p>
            <ul>
                <li>Seit 01.01.2021 gibt es die <strong>„Bundesförderung für effiziente Gebäude“ (BEG)</strong></li>
                <li>Diese vereinheitlicht die bisherigen <strong>Förderungen von KfW und BAFA</strong> und vereinfacht das Antragsverfahren</li>
                <li>Ziel ist es, stärkere Anreize für <strong>energieeffizientes Bauen und Modernisierung</strong> zu setzen</li>
                <li>Die BEG bietet bis zu <strong>40% Zuschuss</strong> für alle Maßnahmen, die zur energetischen Optimierung ihres Wohn- oder Geschäftshauses beitragen</li>
                <li>Mit dem <strong>Feelsmart.-Schnellkonfigurator</strong> erhalten Sie mit wenigen Klicks einen Überblick über die förderfähigen Funktionen und Geräte und ihr persönliches Förderpotenzial</li>
            </ul>
            <p><strong>„Jetzt Planung starten und doppelt sparen“</strong></p>
            <p style={{textAlign: "right"}}>Weitere Informationen</p>
        </div>
        <div className="box funktionenHeader">Die Funktionen des Schnellplaners:</div>
    </div>
}

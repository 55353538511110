

export default function getProducts(room, austattung_produkt){

    const productList = [];

    if(
        room.selectedEquipment["steckdosen_aufputz"] !== undefined
        && room.selectedEquipment["steckdosen_aufputz"] > 0
        && austattung_produkt["steckdose-aufputz"] !== undefined
    ){
        productList.push({
            product: austattung_produkt["steckdose-aufputz"],
            count: room.selectedEquipment["steckdosen_aufputz"]
        })
    }
    return productList;
}


import GetAusstattungProduktObj from "../AusstattungProduktService";
import getBinaereingang from "./AddBinaereingang"
import getSteckdosen from "./AddSteckdosen"
import getSteckdosenUsb from "./AddSteckdosenUsb"
import getSteckdosenRahmen from "./AddSteckdosenRahmen"
import getTelefonDatenanschluss from "./AddTelefonDatenanschluss"
import getTelefonanschluss from "./AddTelefonanschluss"
import getRadioDatenSat from "./AddRadioDatenSat"
import getRadioDatenKabel from "./AddRadioDatenKabel"
import getAnschlussdosenRahmen from "./AddAnschlussdosenRahmen"
import getTuerkommunikation from "./AddTuerkommunikation"
import getSound from "./AddSound"
import getHeizungRaum from "./AddHeizungRaum"
import getHeizungUV from "./AddHeizungUV"
import getAktoren from "./AddAktoren"
import getTaster from "./AddTaster"
import getController from "./AddController"
import getWeatherStation from "./AddWeatherStation"

export default function GenerateProjectService(projectIn, productList, lang){

    let generatedProject = {
        code: null,
        name: projectIn.name,
        selectedAlternatives: (projectIn.selectedAlternatives !== undefined) ? projectIn.selectedAlternatives : {},
        floors: [],
        actionsList: []
    };
    let building = {
        name: projectIn.name,
        type: projectIn.typePro || projectIn.type,
        floors: []
    };
    let systemProductList = [];
    // let systemProductListPro = [];
    let addSpannung = false;
    let actionsList = [];
    let uvActionsList = [];
    let roomUvActionsList = [];
    projectIn.floors.forEach( floorIn => {
        const floor = {
            id: floorIn.id,
            name: floorIn.name,
            type: floorIn.typePro || floorIn.type,
            rooms: []
        };
        const floorPro = {
            name: floorIn.name,
            type: floorIn.typePro || floorIn.type,
            rooms: []
        }
        floorIn.rooms.forEach(roomIn => {
            const room = {
                id: roomIn.id,
                name: roomIn.name,
                type: roomIn.typePro,
                products: [],
                actionsList: [],
                uvActionsList: [],
            }
            const roomPro = {
                name: roomIn.name,
                type: roomIn.typePro,
                devices: [],
                actions: [],
            }
            const austattung_produkt = GetAusstattungProduktObj(projectIn, roomIn, lang);
            console.log("AUSSTATTUNG PRODUCT", austattung_produkt)
            let productListInner = [];
            let productListInnerPro = [];

            const listA = [
                getSteckdosen(roomIn, austattung_produkt),
                // getSteckdosenAufputz(roomIn, austattung_produkt),
                getSteckdosenUsb(roomIn, austattung_produkt),
                getSteckdosenRahmen(roomIn, austattung_produkt),
                getTelefonDatenanschluss(roomIn, austattung_produkt),
                getTelefonanschluss(roomIn, austattung_produkt),
                getRadioDatenSat(roomIn, austattung_produkt),
                getRadioDatenKabel(roomIn, austattung_produkt),
                getAnschlussdosenRahmen(roomIn, austattung_produkt),
                // getRauchmelder(roomIn, austattung_produkt),
                // getBewegungsmelder(roomIn, austattung_produkt)
            ];

            listA.forEach( listA => {
                if (listA.length) {
                    productListInner = productListInner.concat(listA);
                    listA.forEach(lb => {
                        productListInnerPro.push({
                            product: lb.product,
                            count: lb.count,
                            selectedAccessories: [],
                        });
                    });
                }
            });
            const tasterFull = getTaster(roomIn, austattung_produkt, projectIn.selectedAlternatives, actionsList);
            // console.log('knx gettaster', tasterFull)
            if(tasterFull.list.length > 0){
                addSpannung = true;
                productListInner = productListInner.concat(tasterFull.list);
                productListInnerPro = productListInnerPro.concat(tasterFull.listPro);
                tasterFull.actions.forEach(item => room.actionsList.push(item));
                tasterFull.actions.forEach(item => room.uvActionsList.push(item));
            }
            const raumController = getController(roomIn, austattung_produkt, projectIn.selectedAlternatives, actionsList);
            // console.log('raumcontroller', raumController)
            if(raumController.list.length > 0){
                addSpannung = true;
                productListInner = productListInner.concat(raumController.list);
                productListInnerPro = productListInnerPro.concat(raumController.listPro);
                // raumController.actions.forEach(item => room.actionsList.push(item));
                // raumController.actions.forEach(item => room.uvActionsList.push(item));
            }
            // console.log('getTaster', tasterFull)
            const bin = getBinaereingang(roomIn, austattung_produkt);
            if(bin.list.length > 0){
                addSpannung = true;
                systemProductList = systemProductList.concat(bin.list);
                bin.actions.forEach(item => room.actionsList.push(item));
                bin.actions.forEach(item => room.uvActionsList.push(item));
            }

            const heizungFull = getHeizungRaum(roomIn, austattung_produkt, projectIn.selectedAlternatives);
            if(heizungFull.list.length > 0){
                addSpannung = true;
                systemProductList = systemProductList.concat(heizungFull.list);
            }
            room.actionsList = room.actionsList.map((a, idx) => {
                const tmp = productList.find(pl => pl.Artikelnummer.toString() === a.device.toString() || pl.Gtin.toString() === a.device.toString());
                const device = tmp ? tmp.Gtin : 'notFound-' + a.device;
                actionsList.push({...a, device});
                return {...a, device: device};
            });
            room.uvActionsList = room.actionsList.map((a, idx) => {
                const tmp = productList.find(pl => pl.Artikelnummer.toString() === a.device.toString() || pl.Gtin.toString() === a.device.toString());
                const device = tmp ? tmp.Gtin : 'notFound-' + a.device;
                uvActionsList.push({...a, device});
                return {...a, device: device};
            });
            productListInner.forEach( p => {
                if (p.product && p.product !== "") {
                    const productTmp = room.products.find(pi => pi.Artikelnummer && p.product && pi.Artikelnummer.toString() === p.product.toString());
                    if (productTmp === undefined) {
                        const pp = productList.find(pl => pl.Artikelnummer.toString() === p.product.toString());
                        if (pp === undefined) { return; }
                        const productToPush = {...pp, count: p.count, products: []};
                        if (p.products !== undefined) {
                            p.products.forEach(ppz => {
                                const pz = productList.find(pl => pl.Artikelnummer.toString() === ppz.toString());
                                if (pz) {
                                    room.products.push({...pz, count: p.count});
                                    productToPush.products.push({...pz, count: p.count});
                                }
                            });
                        }
                        if (p.alt !== undefined) {
                            const nlist = p.alt.map(pa => {
                                const altList = [];
                                pa.products.forEach(paPro => {
                                    const ppa = productList.find(pl => pl.Artikelnummer.toString() === paPro.toString());
                                    if (pp === undefined) {
                                        return;
                                    }
                                    altList.push(ppa);
                                })
                                pa.products = altList;
                                return pa;
                            });
                            productToPush.productSwitch = nlist;
                        }
                        room.products.push(productToPush);
                    } else {
                        productTmp.count += p.count;
                        productTmp.products.forEach(z => {
                            const pz = productList.find(pl => pl.Artikelnummer.toString() === z.Artikelnummer.toString());
                            room.products.push({...pz, count: p.count});
                        });
                    }

                    // to save productList
                    const pp2 = productList.find(pl => pl.Artikelnummer.toString() === p.product.toString());
                    if (pp2 === undefined) { return; }
                    let selectedAccessoriesList = [];
                    if (p.products && p.products.length) {
                        p.products.forEach(ap => {
                            if (ap) {
                                const pp3 = productList.find(pl => pl.Artikelnummer.toString() === ap.toString());
                                if (pp3 !== undefined) {
                                    selectedAccessoriesList.push(pp3["Gtin"]);
                                }
                            }
                        });
                    }
                    const productToPushPro = {gtin: pp2["Gtin"], selectedAccessories: selectedAccessoriesList, actions: []};
                    room.actionsList.forEach(a => {
                        if (a.puid === p.puid) {
                            productToPushPro.actions.push(a.uuid);
                            roomPro.actions.push({type: a.type, uuid: a.uuid});
                        }
                    });
                    for (let i = 0; i < p.count; i++) {
                        roomPro.devices.push(productToPushPro);
                    }
                }
            });
            floor.rooms.push(room);
            floorPro.rooms.push(roomPro);
            roomUvActionsList = roomUvActionsList.concat(room.actionsList);
        });
        generatedProject.actionsList = actionsList;
        generatedProject.floors.push(floor);
        building.floors.push(floorPro);
    });
    const austattung_produkt = GetAusstattungProduktObj(projectIn, projectIn.floors[0].rooms[0], lang);
    getTuerkommunikation(projectIn, austattung_produkt).forEach(p => {
        if (p.product !== undefined) {
            const pz = productList.find(pl => pl.Artikelnummer.toString() === p.product.toString());
            if (pz) {
                generatedProject = {
                    ...generatedProject,
                    floors: generatedProject.floors.map(floor => {
                        return {
                            ...floor,
                            rooms: floor.rooms.map(room => {
                                if (p.room.typePro === room.type && !p.assigned) {
                                    p = {...p, assigned: true};
                                    return {
                                        ...room,
                                        products: [...room.products, {...pz, count: p.count}]
                                    }
                                }
                                return room;
                            })
                        };
                    })
                };
            }
        }
    });
    systemProductList = systemProductList.concat(getSound(projectIn, austattung_produkt));
    const tmpHeizung = getHeizungUV(projectIn, austattung_produkt, uvActionsList.filter(i => i.type ==='heating_PWM'));
    systemProductList = systemProductList.concat(tmpHeizung.list);
    uvActionsList = uvActionsList.filter(i => i.type !== 'heating_PWM').concat(tmpHeizung.actions);
    if (tmpHeizung.newActions && tmpHeizung.newActions.length) {
        let assignedToRoom = false;
        building = {
            ...building,
            floors: building.floors.map(floor => {
                return {
                    ...floor,
                    rooms: floor.rooms.map(room => {
                        if (tmpHeizung.newActions.length && !assignedToRoom) {
                            return {
                                ...room,
                                actions: [...room.actions, ...tmpHeizung.newActions]
                            }
                        }
                        return room;
                    })
                };
            })
        };
    }
    const akt = getAktoren(projectIn, austattung_produkt, roomUvActionsList.filter(i => i.type !=='heating_PWM'));
    if(akt.list.length > 0){
        addSpannung = true;
        systemProductList = systemProductList.concat(akt.list);
        uvActionsList = uvActionsList.filter(i => i.type === 'heating_PWM').concat(akt.actions);
    }
    if(addSpannung){
        systemProductList.push(
            {
                product: austattung_produkt["spannung"],
                count: 1
            }
        );
    }
    // console.log('akt', akt, uvActionsList, systemProductList)
    const weatherStation = getWeatherStation(projectIn, austattung_produkt, projectIn.selectedAlternatives);
    if(weatherStation.length > 0){
        addSpannung = true;
        systemProductList = systemProductList.concat(weatherStation);
    }
    systemProductList = systemProductList.filter(i => i.count > 0);
    uvActionsList = uvActionsList.map((a, idx) => {
        const tmp = productList.find(pl => pl.Artikelnummer.toString() === a.device.toString() || pl.Gtin.toString() === a.device.toString());
        const device = tmp ? tmp.Gtin : a.device;
        return {...a, device: device};
    });
    if(systemProductList.length > 0){
        const uv = {
            id: "Unterverteilung", //uuidv4(), todo
            name: "Unterverteilung",
            type: "cc245399-46d7-42fc-a576-0d11ecf0db71",
            products: [],
            actionsList: uvActionsList,
            installationLocations: [
                {
                    id: "InstallationLocation",
                    name: "[Unterverteiler]",
                    type: "4e6ab71c-beee-4f8b-9c77-1bc98394d104",
                    isSubdistribution: true,
                    products: [],
                    actions: []
                }
            ]
        }
        const uvPro = {
            name: "Unterverteilung",
            type: "cc245399-46d7-42fc-a576-0d11ecf0db71",
            devices: [],
            actions: [],
            installationLocations: [
                {
                    id: "InstallationLocation",
                    name: "[Unterverteiler]",
                    type: "4e6ab71c-beee-4f8b-9c77-1bc98394d104",
                    isSubdistribution: true,
                    products: [],
                    actions: []
                }
            ]
        }
        const uvProducts = [];
        const uvProProducts = [];
        systemProductList.forEach( p => {
            if (p.product) {
                const productTmp = uvProducts.find(pi => pi.Artikelnummer.toString() === p.product.toString());
                if (productTmp === undefined) {
                    const pp = productList.find(pl => pl.Artikelnummer.toString() === p.product.toString());
                    if (pp === undefined) { console.log('product', p.product, ' <= not found !!'); return; }
                    const productToPush = {...pp, count: p.count};
                    if (p.alt !== undefined) {
                        const nlist = p.alt.map(pa => {
                            const altList = [];
                            pa.products.forEach(paPro => {
                                const ppa = productList.find(pl => pl.Artikelnummer.toString() === paPro.toString());
                                if (pp === undefined) {
                                    return;
                                }
                                altList.push(ppa);
                            })
                            pa.products = altList;
                            return pa;
                        });
                        productToPush.productSwitch = nlist;
                    }
                    uvProducts.push(productToPush);
                } else {
                    productTmp.count += p.count;
                }

                const pp2 = productList.find(pl => pl.Artikelnummer.toString() === p.product.toString());
                if (pp2 === undefined) { return; }
                let selectedAccessoriesList = [];
                if (p.products) {
                    p.products.forEach(ap => {
                        const pp3 = productList.find(pl => pl.Artikelnummer.toString() === ap.toString());
                        if (pp3 !== undefined) {
                            selectedAccessoriesList.push(pp3["Gtin"]);
                        }
                    });
                }
                const productToPush = {gtin: pp2["Gtin"], selectedAccessories: selectedAccessoriesList, actions: []};
                if (p.actions) {
                    p.actions.forEach(a => productToPush.actions.push(a.uuid));
                }
                uvProProducts.push(productToPush);
            }
        });
        uvProducts.forEach(i => uv.installationLocations[0].products.push(i));
        uvProducts.forEach(i => uv.products.push(i));
        uvProProducts.forEach(i => uvPro.installationLocations[0].products.push(i));
        generatedProject.floors[0].rooms.push(uv);
        building.floors[0].rooms.push(uvPro);
    }
    let projectTypes = {
        knx: "KNX",
        easy: "HAGER_KNX_EASY",
        konventionell: "CONVENTIONAL"
    };
    // console.log('uvProducts', {...building}, {...generatedProject}, {...systemProductList});
    building = {
        ...building,
        floors: building.floors.map(floor => {
            return {
                ...floor,
                rooms: floor.rooms.map(room => {
                    if (room.type === "cc245399-46d7-42fc-a576-0d11ecf0db71") {
                        let newRoom = {...room, actions: [], devices: [], installationLocations: room.installationLocations.map(il => {return {...il, devices: il.products};})};
                        newRoom = {
                            ...newRoom,
                            installationLocations: newRoom.installationLocations.map(il => {
                                if (il.products) { delete il.products; }
                                return il;
                            })
                        };
                        // newRoom = {...newRoom, rooms: [...newRoom.installationLocations]};
                        return newRoom;
                    }
                    return {
                        ...room,
                        actions: [...new Map(room.actions.map(item => [item['uuid'], item])).values()]
                    };
                })
            };
        })
    };
    const projectPro = {
        name: projectIn.projectName,
        type: projectTypes[projectIn.projectType],
        buildings: [building]
    };
    return {...projectIn, generatedProject: generatedProject, projectPro: projectPro}

}

import React from "react";
import {ReactComponent as IconWohnung} from "./../images/IconWohnung.svg";
import {ReactComponent as IconDefault} from "./../images/IconDefault.svg";
import {ReactComponent as IconEinfamilienhaus} from "./../images/IconEinfamilienhaus.svg";
import {ReactComponent as IconEinfamilienhausGarage} from "./../images/IconEinfamilienhausGarage.svg";
import {ReactComponent as IconEinfamilienhausGarageGarten} from "./../images/IconEinfamilienhausGarageGarten.svg";
import {ReactComponent as IconStadtvillaGarageGarten} from "./../images/IconStadtvillaGarageGarten.svg";
import checkIcon from "../images/check_mark.svg";

export default function ProjectSelectComponent({selectedIx, projects, onSelectProject, projectType, subProjectType}){

    function getIcon (icon){
        switch (icon) {
            case 'IconWohnung':
                return <IconWohnung />
            case 'IconEinfamilienhaus':
                return <IconEinfamilienhaus />
            case 'IconEinfamilienhausGarage':
                return <IconEinfamilienhausGarage />
            case 'IconEinfamilienhausGarageGarten':
                return <IconEinfamilienhausGarageGarten />
            case 'IconStadtvillaGarageGarten':
                return <IconStadtvillaGarageGarten />
            default:
                return <IconDefault />
        }

    }


    function onSelectProjectType(project, ix){
        onSelectProject({...project, selectedMusterIx: ix, projectType: projectType, subProjectType: subProjectType});
    }

    return <div className="addProjectList row fs-box-white">
        {projects.map((r, ix) =>
            <div key={ix} className={"addProjectListItem col-1" + (ix === selectedIx ? " active" : "")}  onClick={event => onSelectProjectType(r, ix)}>
                {getIcon(r.icon)}
                <div className="noBrake" style={{paddingLeft: "9px", paddingRight: ix === selectedIx ? "33px" : "9px", position: "relative"}}>
                    <span dangerouslySetInnerHTML={{__html: r.name}}></span> {ix === selectedIx ? <img src={checkIcon} width="33" height="33" style={{position: "absolute", top: "-8px"}} alt={"mark"} /> : <></>}
                </div>
            </div>)
        }
    </div>
}



export default function getProducts(room, austattung_produkt){

    const productList = [];

    if(
        room.selectedEquipment["telefonanschlusss"] !== undefined
        && room.selectedEquipment["telefonanschlusss"] > 0
        && austattung_produkt["tae"] !== undefined
        && austattung_produkt["tae-zentralstueck"] !== undefined
    ){
        productList.push({
            product: austattung_produkt["tae"],
            count: room.selectedEquipment["telefonanschlusss"]
        });
        productList.push({
            product: austattung_produkt["tae-zentralstueck"],
            count: room.selectedEquipment["telefonanschlusss"]
        });
    }

    return productList;
}

import React, {useEffect} from "react";
import knx_austattung_produkt from "../data/knx_austattung_produkt.json";
import knx_austattung_produkt_en from "../data/knx_austattung_produkt_en.json";
import knx_austattung_produkt_fr from "../data/knx_austattung_produkt_fr.json";
import knx_austattung_produkt_it from "../data/knx_austattung_produkt_it.json";
import knx_austattung_produkt_nl from "../data/knx_austattung_produkt_nl.json";
import knx_austattung_produkt_at from "../data/knx_austattung_produkt_at.json";
import knx_austattung_produkt_es from "../data/knx_austattung_produkt_es.json";
import konnventionell_austattung_produkt_en from "../data/konventionell_austattung_produkt_en.json";
import konnventionell_austattung_produkt_fr from "../data/konventionell_austattung_produkt_fr.json";
import konnventionell_austattung_produkt_it from "../data/konventionell_austattung_produkt_it.json";
import konnventionell_austattung_produkt_nl from "../data/konventionell_austattung_produkt_nl.json";
import konnventionell_austattung_produkt_at from "../data/konventionell_austattung_produkt_at.json";
import konnventionell_austattung_produkt_es from "../data/konventionell_austattung_produkt_es.json";
import konnventionell_austattung_produkt from "../data/konventionell_austattung_produkt.json";
import home_austattung_produkt_en from "../data/home_austattung_produkt_en.json";
import home_austattung_produkt_fr from "../data/home_austattung_produkt_fr.json";
import home_austattung_produkt_it from "../data/home_austattung_produkt_it.json";
import home_austattung_produkt_nl from "../data/home_austattung_produkt_nl.json";
import home_austattung_produkt_at from "../data/home_austattung_produkt_at.json";
import home_austattung_produkt_es from "../data/home_austattung_produkt_es.json";
import home_austattung_produkt from "../data/home_austattung_produkt.json";
import products_en from "../data/products_en.json";
import products_es from "../data/products_es.json";
import products_nl from "../data/products_nl.json";
import products_fr from "../data/products_fr.json";
import products_it from "../data/products_it.json";
import products_at from "../data/products_at.json";
import products from "../data/products.json";
import {useTranslation} from "react-i18next";

export default function StepVerifyComponent({project, projectSelectList, setSelectedProject, updateProject, schalterdesign, startProject, setProjectCode, projectCode, loadProjectFromCode, trackingObj, isIFrame, jumpTo, setPrCode}){

    const [designs, setDesigns] = React.useState([]);
    const [austattungs, setAustattungs] = React.useState([]);
    const [isHome, setIsHome] = React.useState(false);
    const {t, i18n} = useTranslation();
    const language = i18n.resolvedLanguage;
    let resHtml = '';

    function handleOnChangeDesign(e) {
        // console.log('onchange Design', e)
        let reader = new FileReader();
        reader.onload = onReaderLoadDesign;
        if (e.target.files[0] && e.target.files[0].name.indexOf('home') >= 0) { setIsHome(true); }
        reader.readAsText(e.target.files[0]);
    }
    function onReaderLoadDesign(e){
        // console.log(e.target.result);
        let obj = JSON.parse(e.target.result);
        alert_data_design(obj);
    }
    function alert_data_design(obj){
        // console.log('alert_data_design', obj)
        obj.forEach(item => {
            // console.log('parsing item', item)
            const serie = item.id;
            let colorId = '';
            let rahmenCid = '';
            item.operatingConcepts.forEach(oc => {
                oc.colorsTaster.forEach(ct => {
                    colorId = ct.colorId;
                    ct.colorsRahmen.forEach(cr => {
                        rahmenCid = cr.colorId;
                        // designs[serie + '|' + colorId + '|' + rahmenCid] = { serie, colorId, rahmenCid };
                        designs.push({ serie, colorId, rahmenCid });
                        setDesigns(designs);
                        // console.log('pushed design { ', serie, colorId, rahmenCid, ' }')
                    });
                });
            });
        });
        // console.log('designs', designs)
    }
    function handleOnChangeAusstattung(e) {
        // console.log('onchange Ausstattung', e)
        let reader = new FileReader();
        reader.onload = onReaderLoadAusstattung;
        if (e.target.files[0] && e.target.files[0].name.indexOf('home') >= 0) { setIsHome(true); }
        reader.readAsText(e.target.files[0]);
    }
    function onReaderLoadAusstattung(e){
        // console.log(e.target.result);
        let obj = JSON.parse(e.target.result);
        alert_data_austattung(obj);
    }
    function alert_data_austattung(obj){
        // console.log('alert_data_austattung', obj)
        obj.forEach(item => {
            // console.log('parsing item', item)
            const serie = item.serie;
            const colorId = isHome
                ? (['JUNG-LC_home', 'JUNG-A-Cube_home', 'JUNG-A-Viva_home', 'JUNG-Aflow_home'].includes(serie) ? item.wippe : item["scha-aufsatz-2"])
                : item.wippe;
            const rahmenCid = item.rahmen;
            // austattungs[serie + '|' + colorId + '|' + rahmenCid] = { serie, colorId, rahmenCid };
            austattungs.push({ serie, colorId, rahmenCid, cField: ['JUNG-LC_home', 'JUNG-A-Cube_home', 'JUNG-A-Viva_home', 'JUNG-Aflow_home'].includes(serie) ? 'wippe' : 'scha-aufsatz-2' });
            setAustattungs(austattungs);
            // console.log('pushed austattung { ', serie, colorId, rahmenCid, ' }')
        });
        // console.log('ausstattungs', austattungs, 'designs', designs)
    }
    function handleVerifyDesignWithAusstattung(e) {
        // console.log('handleVerifyDesignWithAusstattung', e, austattungs.length, designs.length, '=', !austattungs.length || !designs.length)
        let res = document.getElementById('result');
        // console.log('result', res, res.innerHTML)
        setTimeout(() => {startCompare(res);}, 500);
    }

    function startCompare(res) {
        // console.log('startcompare', res, designs, austattungs)
        resHtml = '';
        designs.forEach(dValue => {
            const key = dValue.serie + '|' + dValue.colorId + '|' + dValue.rahmenCid;
            const found = austattungs.filter(i => i.serie === dValue.serie && i.colorId === dValue.colorId && i.rahmenCid === dValue.rahmenCid)[0];
            if (!found) {
                // console.log('parsing dValue', key, found)
                appendRow('<div>Design Serie: '+ dValue.serie + ' | ColorId: ' + dValue.colorId + ' | Rahmen: ' + dValue.rahmenCid + ' <= nicht gefunden!</div>');
            } else {
                // console.log('parsing dValue', key, false)
            }
        });
        austattungs.forEach(aValue => {
            const key = aValue.serie + '|' + aValue.colorId + '|' + aValue.rahmenCid;
            const found = designs.filter(i => i.serie === aValue.serie && i.colorId === aValue.colorId && i.rahmenCid === aValue.rahmenCid);
            if (!found.length) {
                // console.log('parsing aValue', key, false)
                appendRow('<div>Austattung Serie: ' + aValue.serie + ' | ColorId: ' + aValue.colorId + ' | Rahmen: ' + aValue.rahmenCid + ' <= nicht gefunden!' + (isHome ? ('ColorId Schlüssel: ' + aValue.cField) : '') + '</div>');
            }
        });
        res.innerHTML = resHtml;
        if (resHtml === '') {
            res.innerHTML = 'Schlüsselprüfung erfolgreich !';
        }
    }
    function appendRow(data) {
        resHtml += '<br/>' + data;
        // console.log('resHtml is now', resHtml)
    }

    function startCompareProducts(res) {
        let knx = knx_austattung_produkt;
        let home = home_austattung_produkt;
        let sw = konnventionell_austattung_produkt;
        let pr = products;
        let resHTML = '<p>';console.log('res', res);
        switch(language) {
            case 'nl': knx = knx_austattung_produkt_nl;home = home_austattung_produkt_nl; sw = konnventionell_austattung_produkt_nl;pr=products_nl;break;
            case 'it': knx = knx_austattung_produkt_it;home = home_austattung_produkt_it; sw = konnventionell_austattung_produkt_it;pr=products_it;break;
            case 'es': knx = knx_austattung_produkt_es;home = home_austattung_produkt_es; sw = konnventionell_austattung_produkt_es;pr=products_es;break;
            case 'fr': knx = knx_austattung_produkt_fr;home = home_austattung_produkt_fr; sw = konnventionell_austattung_produkt_fr;pr=products_fr;break;
            case 'at': knx = knx_austattung_produkt_at;home = home_austattung_produkt_at; sw = konnventionell_austattung_produkt_at;pr=products_at;break;
            case 'en': knx = knx_austattung_produkt_en;home = home_austattung_produkt_en; sw = konnventionell_austattung_produkt_en;pr=products_en;break;
        }
        const notfound = [];
        [knx, home, sw].map (arr => {
            arr.map(item => {
                Object.keys(item).map(key => {
                    const artno = item[key];
                    let found = false;
                    if (artno !== "" && artno !== null && key !== "serie" && key !== "Bedienkonzept") {
                        pr.map(p => {
                            if (p["Artikelnummer"] === artno) { found = true;}
                        });
                        if (!found) {
                            if (!notfound.includes(item[key])) {
                                notfound.push(item[key]);
                            }
                        }
                        console.log('parsing', key, item[key], found)
                    } else {
                        console.log('parsing', key, item[key], "is null or empty")
                    }
                });
            });
        });
        console.log('notfound', notfound)
        notfound.map(nfitem => {
            console.log('parsing notfounditem', nfitem)
            resHTML += nfitem + '<br/>';
        });
        resHTML += '</p>';
        res.innerHTML += resHTML;
        alert("done");
    }
    function checkproducts() {
        let res = document.getElementById('result_products');
        console.log('result', res, res.innerHTML);
        setTimeout(() => {startCompareProducts(res);}, 500);
    }

    return <div>
        <form style={{padding: '15px', width: '100%'}}>
            <div style={{marginBottom: '10px', display: 'flex', width: '100%'}}>
                <label style={{minWidth: '250px'}}>Schalterdesign JSON</label>
                <input type={"file"} name={"design"} onChange={handleOnChangeDesign} />
            </div>
            <div style={{marginBottom: '10px', display: 'flex', width: '100%'}}>
                <label style={{minWidth: '250px'}}>Ausstattung JSON</label>
                <input type={"file"} name={"design"} onChange={handleOnChangeAusstattung} />
            </div>
            <div className={"form-row"}>
                <button type={"button"} onClick={handleVerifyDesignWithAusstattung}>Prüfen</button>
            </div>
        </form>
        <div id="result"></div>
        <div style={{padding: '15px', width: '100%'}}>
            <button onClick={checkproducts}>Product.json prüfen</button>
        </div>
        <div id="result_products"></div>
    </div>;
}

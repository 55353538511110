

export default function getProducts(room, austattung_produkt, selectedAlternatives){

    const productList = {list:[], listPro: []};

    // let countVentil = room.heatingCount + room.floorheatingCount;
    // let addRTtAktor = false;

    if(
        room.selectedEquipment["heizen_heizkoerper"] !== undefined
        && room.selectedEquipment["heizen_heizkoerper"] > 0
    ){
        productList.list.push({
            product: austattung_produkt["rt-ventil-1"],
            count: room.heatingCount
        });
    }

    if(
        room.selectedEquipment["heizen_fussbodenheizung"] !== undefined
        && room.selectedEquipment["heizen_fussbodenheizung"] > 0
    ){
        productList.list.push({
            product: austattung_produkt["rt-ventil-1"],
            count: room.floorheatingCount
        });
    }
    if(
        room.selectedEquipment["heizen_fußbodenheizung"] !== undefined
        && room.selectedEquipment["heizen_fußbodenheizung"] > 0
    ){
        productList.list.push({
            product: austattung_produkt["rt-ventil-1"],
            count: room.floorheatingCount
        });
    }

    return productList;
}

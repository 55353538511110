import React, {useEffect} from "react"
import EquimentSelectComponent from "./EquimentSelectComponent";
import {useTranslation} from "react-i18next";
// import SelectConfLevelComponent from "./SelectConfLevelComponent";

export default function StepFourComponent({project, equipmentData, updateProject, updateProjectWithNewLevel, trackingObj, jumpTo, isIFrame}){
    const {t} = useTranslation();
    useEffect(() => {
        if (!isIFrame) { setTimeout(() => {document.body.style.overflow = 'unset'; }, 200); }
    }, []);
    function setConfLevel(e, level){
        e.stopPropagation();
        updateProjectWithNewLevel({...project, selectedLevel: level});
    }
console.log('equipmentdata', equipmentData)
    return <div>
        <div className="fs-container ptb-25 mt-15">
            {project.projectType !== 'konventionell' ? <>
                    <div className="padding-container">
                        <div className="section-title" dangerouslySetInnerHTML={{__html: t('app.step.additionalEquipment.title')}}></div>
                        <div className="section-text mb-15" dangerouslySetInnerHTML={{__html: t('app.step.additionalEquipment.description')}}></div>
                    </div>
                </>
                : <>
                    <div className="padding-container">
                        <div className="section-title" dangerouslySetInnerHTML={{__html: t('app.step.additionalEquipment.title.konventionell')}}></div>
                        <div className="section-text mb-15" dangerouslySetInnerHTML={{__html: t('app.step.additionalEquipment.description.konventionell')}}></div>
                    </div>
                </>
            }
        </div>
        { equipmentData.map( (item, idx) => <EquimentSelectComponent project={project} equipmentData={item} updateProject={updateProject} key={item.id} opened={window.innerWidth > 800} setLevel={setConfLevel} starsClickable={false} allowClose={false} noArrow={true} noStars={true} withPadding={true} jumpTo={jumpTo} itemIdx={idx} />)}
    </div>
}

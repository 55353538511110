

export default function getProducts(room, austattung_produkt){

    const productList = [];

    if(
        room.selectedEquipment["radio_datenanschluss_sat"] !== undefined
        && room.selectedEquipment["radio_datenanschluss_sat"] > 0
        && austattung_produkt["sat"] !== undefined
        && austattung_produkt["sat-zentralstueck"] !== undefined
    ){
        productList.push({
            product: austattung_produkt["sat"],
            count: room.selectedEquipment["radio_datenanschluss_sat"]
        });
        productList.push({
            product: austattung_produkt["sat-zentralstueck"],
            count: room.selectedEquipment["radio_datenanschluss_sat"]
        });
    }

    return productList;
}

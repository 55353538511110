import {v4 as uuidv4} from "uuid";

export class GenerateRoomToAddService{

    static defaultDesignConfig = undefined;

    static resetDefaultDesignConfig(){
        GenerateRoomToAddService.defaultDesignConfig = undefined;
    }

    static getDefaultDesignConfig(schalterdesign){
        if(GenerateRoomToAddService.defaultDesignConfig === undefined){

            var serie = schalterdesign.filter(s => s.isDefault === true)[0];
            serie = (serie !== undefined) ? serie : schalterdesign[0];
            var selectedOperationConcept = serie.operatingConcepts.filter(t => t.isDefault === true)[0];
            selectedOperationConcept = (selectedOperationConcept !== undefined) ? selectedOperationConcept : serie.operatingConcepts[0]
            var color1 =  selectedOperationConcept.colorsTaster.filter(t => t.isDefault === true)[0];
            color1 = (color1 !== undefined) ? color1 : selectedOperationConcept.colorsTaster[0];
            var color2 =  color1.colorsRahmen.filter(t => t.isDefault === true)[0];
            color2 = (color2 !== undefined) ? color2 : color1.colorsRahmen[0];

            GenerateRoomToAddService.defaultDesignConfig = {
                selectedSerie: serie,
                selectedOperationConcept: selectedOperationConcept,
                selectedColor1: color1,
                selectedColor2: color2
            };
        }
        return GenerateRoomToAddService.defaultDesignConfig;
    }

    static generate({roomIn, selectedLevel, schalterdesign, subProjectType = ''}) {
        const levelDataList = roomIn.defaultEquipment.filter(de => (de.level === selectedLevel));

        const levelData = levelDataList.length > 0 ? levelDataList[0] : {};

        var roomOut = {
            id: uuidv4(),
            type: roomIn.type,
            typePro: roomIn.typePro,
            name: roomIn.name,
            doorCount: roomIn.doorCount,
            windowCount: roomIn.windowCount,
            heatingCount: roomIn.heatingCount,
            floorheatingCount: roomIn.floorheatingCount,
            selectedEquipment: levelData.values ? {...levelData.values} : {},
            selectedDesign: roomIn.selectedDesign ? roomIn.selectedDesign : GenerateRoomToAddService.getDefaultDesignConfig(schalterdesign)
        };
        if (subProjectType === 'umbau') {
            roomOut = {
                ...roomOut,
                doorCount: 0,
                windowCount: 0,
                heatingCount: 0,
                floorheatingCount: 0,
            };
            if (levelData.values) {
                Object.keys(levelData.values).forEach(key => {
                    const setZero = key.indexOf('telefon_') >= 0 || key.indexOf('radio_') >= 0;
                    roomOut = { ...roomOut, selectedEquipment: {...roomOut.selectedEquipment, [key]: !setZero ? 0 : roomOut.selectedEquipment[key]} };
                });
            }
        }
        return roomOut;
    }
}

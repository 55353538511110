

export default function getProducts(room, austattung_produkt){

    const productList = [];

    let count_telefon_datenanschluss = 0;
    let count_telefonanschlusss = 0;
    let count_radio_datenanschluss_sat = 0;
    let count_radio_datenanschluss_kabel = 0;

    if(
        room.selectedEquipment["telefon_datenanschluss"] !== undefined
        && room.selectedEquipment["telefon_datenanschluss"] > 0
    ){
        count_telefon_datenanschluss += room.selectedEquipment["telefon_datenanschluss"];
    }
    if(
        room.selectedEquipment["telefonanschlusss"] !== undefined
        && room.selectedEquipment["telefonanschlusss"] > 0
    ){
        count_telefonanschlusss += room.selectedEquipment["telefonanschlusss"];
    }
    if(
        room.selectedEquipment["radio_datenanschluss_sat"] !== undefined
        && room.selectedEquipment["radio_datenanschluss_sat"] > 0
    ){
        count_radio_datenanschluss_sat += room.selectedEquipment["radio_datenanschluss_sat"];
    }
    if(
        room.selectedEquipment["radio_datenanschluss_kabel"] !== undefined
        && room.selectedEquipment["radio_datenanschluss_kabel"] > 0
    ){
        count_radio_datenanschluss_kabel += room.selectedEquipment["radio_datenanschluss_kabel"];
    }

    let frameCount1 = 0;
    let frameCount2 = 0;
    let frameCount3 = 0;
    let frameCount4 = 0;

    while(
        count_telefon_datenanschluss > 0
        || count_telefonanschlusss > 0
        || count_radio_datenanschluss_sat > 0
        || count_radio_datenanschluss_kabel > 0
    ){
        let frameSize = 0;
        if(count_telefon_datenanschluss > 0){
            frameSize++;
            count_telefon_datenanschluss--;
        }
        if(count_telefonanschlusss > 0){
            frameSize++;
            count_telefonanschlusss--;
        }
        if(count_radio_datenanschluss_sat > 0){
            frameSize++;
            count_radio_datenanschluss_sat--;
        }
        if(count_radio_datenanschluss_kabel > 0){
            frameSize++;
            count_radio_datenanschluss_kabel--;
        }

        switch(frameSize){
            case 1:
                frameCount1++;
                break;
            case 2:
                frameCount2++;
                break;
            case 3:
                frameCount3++;
                break;
            case 4:
                frameCount4++;
                break;
            default:
        }
    }

    [
        {key: "r-4f", count: frameCount4},
        {key: "r-3f", count: frameCount3},
        {key: "r-2f", count: frameCount2},
        {key: "r-1f", count: frameCount1}
    ].forEach(frame => {
        if(
            frame.count > 0
            && austattung_produkt[frame.key] !== undefined
        ){
            productList.push({
                product: austattung_produkt[frame.key],
                count: frame.count
            });
        }
    });

    return productList;
}



export default function getProducts(room, austattung_produkt){

    const productList = [];

    let fullCount = 0;

    if(
        room.selectedEquipment["steckdosen"] !== undefined
        && room.selectedEquipment["steckdosen"] > 0
    ){
        fullCount += room.selectedEquipment["steckdosen"];
    }
    if(
        room.selectedEquipment["usb_steckdosen"] !== undefined
        && room.selectedEquipment["usb_steckdosen"] > 0
    ){
        fullCount += room.selectedEquipment["usb_steckdosen"];
    }
    if(
        room.selectedEquipment["schaltbare_steckdosen"] !== undefined
        && room.selectedEquipment["schaltbare_steckdosen"] > 0
    ){
        fullCount += room.selectedEquipment["schaltbare_steckdosen"];
    }

    let frameCount1 = 0;
    let frameCount2 = 0;
    let frameCount3 = 0;
    let frameCount4 = 0;

    while(fullCount >= 4){
        frameCount1++;
        frameCount2++;
        frameCount3++;
        frameCount4++;
        fullCount -= 4;
    }

    if(fullCount > 2){
        frameCount3++;
    }
    if(fullCount > 1){
        frameCount2++;
    }
    if(fullCount > 0){
        frameCount1++;
    }

    [frameCount1, frameCount2, frameCount3,frameCount4].forEach(count => {
        let innerCount = count;
        [
            {factor: 5, key: "r-5f"},
            {factor: 4, key: "r-4f"},
            {factor: 3, key: "r-3f"},
            {factor: 2, key: "r-2f"},
            {factor: 1, key: "r-1f"}
        ].forEach(frame => {
            if(
                innerCount / frame.factor >= 1
                && austattung_produkt[frame.key] !== undefined
            ){
                const c = Math.ceil(innerCount / frame.factor);
                productList.push({
                    product: austattung_produkt[frame.key],
                    count: c
                })
                innerCount -= c * frame.factor;
            }
        });
    });

    return productList;
}

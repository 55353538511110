
import {Navigate, Route, Routes, useParams} from "react-router-dom";
import StartComponent from "./StartComponent";
import MyLoginComponent from "./MyLoginComponent";
import { OAuthPopup, useOAuth2 } from "@tasoskakour/react-use-oauth2";
import {useTranslation} from "react-i18next";


export default function RouterComponent(){
    const {t, i18n} = useTranslation();
    const language = i18n.resolvedLanguage;

    // if (language !== 'nl') {
        return <Routes>
            <Route path={"/"} element={<Navigate to={"/" + language + "/home"}/>}/>
            <Route path={"/:lang/:confType/myjung/callback"} element={<OAuthPopup/>}/>
            <Route path={"/:confType/myjung/callback"} element={<OAuthPopup/>}/>
            <Route path={"/:lang/:confType/myjung/:code"} element={<MyLoginComponent/>}/>
            <Route path={"/:lang/:confType/myjung"} element={<MyLoginComponent/>}/>
            <Route path={"/:confType/myjung"} element={<MyLoginComponent/>}/>
            <Route path={"/:confType/myjung/:code"} element={<MyLoginComponent/>}/>
            <Route path={"/:lang/:confType/:code"} element={<StartComponent/>}/>
            <Route path={"/:lang/:confType/"} element={<StartComponent/>}/>
            <Route path={"/:confType/"} element={<StartComponent/>}/>
        </Routes>
    // }
    // else {
    //     return <Routes>
    //         <Route path={"/"} element={<Navigate to={"/home"}/>}/>
    //         <Route path={"/:confType/myjung/callback"} element={<OAuthPopup/>}/>
    //         <Route path={"/:confType/myjung/:code"} element={<MyLoginComponent/>}/>
    //         <Route path={"/:confType/myjung"} element={<MyLoginComponent/>}/>
    //         <Route path={"/:confType/:code"} element={<StartComponent/>}/>
    //         <Route path={"/:confType/"} element={<StartComponent/>}/>
    //     </Routes>
    // }

}

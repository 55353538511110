

export default function getProducts(project, austattung_produkt){

    const productList = [];
    // if (project.selectedEquipment["tuerko-haus"] !== undefined
    //     || Boolean(project.selectedEquipment["tuerko-haus"])
    // ) {
    //     productList.push({
    //         product: austattung_produkt["innenstation-video"],
    //         count: 1
    //     })
    //     productList.push({
    //         product: austattung_produkt["rahmen-design-video"],
    //         count: 1
    //     })
    // }
    // if (project.selectedEquipment["tuerko"] !== undefined
    //     || Boolean(project.selectedEquipment["tuerko"])
    // ) {
    //     if (project.selectedEquipment["tuerko"] > 0) {
    //         productList.push({
    //             product: austattung_produkt["innenstation"],
    //             count: 1
    //         })
    //         productList.push({
    //             product: austattung_produkt["rahmen-t-audio"],
    //             count: 1
    //         })
    //     }
    // }
    const list = [
        { opt: "tuerko-audio", products: ["innenstation", "rahmen-t-audio"], type: 'count' },
        { opt: "tuerko-video", products: ["innenstation-video", "rahmen-design-video"], type: 'count' },
        { opt: "tuerko-app", products: ["siedle"], type: 'bool' }
    ];
    const inRooms = ["46e3e159-d5a5-4623-9cd6-8f66f5fad7fa", "d8cf675b-2a77-42b9-8be7-5b9f7b6ff4f9"];
    function getFirstRoom() {
        let r;
        project.floors.forEach(floor => {
            floor.rooms.forEach(room => {
                if (!r) { r = room; }
            });
        });
        return r;
    }
    function getRoom() {
        let sroom = getFirstRoom();
        let found = false;
        project.floors.forEach(floor => {
            floor.rooms.forEach(room => {
                if (inRooms.includes(room.type) && !found) { sroom = room; found = true; }
            });
        });
        return sroom;
    }
    const productRoom = getRoom();
    list.forEach(item => {
        if (project.selectedEquipment[item.opt] !== undefined
            && Boolean(project.selectedEquipment[item.opt])
        ) {
            item.products.forEach(p => {
                if (austattung_produkt[p]) {
                    productList.push({
                        product: austattung_produkt[p],
                        count: item.type === 'bool' ? 1 : project.selectedEquipment[item.opt],
                        room: productRoom
                    });
                }
            });
        }
    });

    return productList;
}

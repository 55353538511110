

export default function getProducts(project, austattung_produkt, actionsList){

    const productList = [];
    const actions = [];

    // const checkList = [
    //     {
    //         key: "schaltbare_beleuchtung",
    //         factor: 1
    //     },
    //     {
    //         key: "schaltbare_steckdosen",
    //         factor: 1
    //     },
    //     // {
    //     //     key: "verschattung_steuern",
    //     //     factor: 2
    //     // }
    // ];

    let countForCheck = 0;
    let countForDimmen = 0;
    let countForRollade = 0;

    const uniqueActions = [];
    actionsList.forEach(a => {
        if (uniqueActions.filter(i => i.uuid === a.uuid)[0] === undefined) { uniqueActions.push(a); }
    });
    actionsList = uniqueActions;
    uniqueActions.forEach(a => {
        // console.log('action', a)
        if (a.type.indexOf('switch') >= 0) { countForCheck += 1; }
        if (a.type.indexOf('dim') >= 0) { countForDimmen += 1; }
        if (a.type.indexOf('shading') >= 0) { countForRollade += 1; }
    })

    // project.floors.forEach(floor => {
    //     floor.rooms.forEach(room => {
    //         checkList.forEach(item => {
    //             if(
    //                 room.selectedEquipment[item.key] !== undefined
    //                 && room.selectedEquipment[item.key] > 0
    //             ){
    //                 countForCheck += room.selectedEquipment[item.key] * item.factor; // * room.doorCount;
    //             }
    //         });
    //         if(
    //             room.selectedEquipment["dimmbare_beleuchtung"] !== undefined
    //             && room.selectedEquipment["dimmbare_beleuchtung"] > 0
    //         ){
    //             // countForDimmen += room.doorCount ? room.selectedEquipment["dimmbare_beleuchtung"] * room.doorCount : room.selectedEquipment["dimmbare_beleuchtung"];
    //             countForDimmen += room.selectedEquipment["dimmbare_beleuchtung"];
    //         }
    //         if(
    //             room.selectedEquipment["verschattung_steuern"] !== undefined
    //             && room.selectedEquipment["verschattung_steuern"] > 0
    //         ){
    //             countForRollade += room.windowCount * 2; //room.selectedEquipment["verschattung_steuern"] * 2;
    //         }
    //     })
    // })
// console.log('dimmen', countForDimmen, 'verschatten', countForRollade, 'switch', countForCheck);
    if(countForDimmen > 0){
        [
            {factor: 4, key: "dimm-aktor-4"},
            {factor: 3, key: "dimm-aktor-3"},
            {factor: 2, key: "dimm-aktor-2"},
            {factor: 1, key: "dimm-aktor-1"}
        ].forEach(aktor => {
            if(
                countForDimmen / aktor.factor >= 1
                && austattung_produkt[aktor.key] !== undefined
            ){
                const c = Math.floor(countForDimmen / aktor.factor);
                for (let a = 1; a <= c; a++) {
                    let product = {
                        product: austattung_produkt[aktor.key],
                        count: 1,
                        actions: []
                    };
                    for (let i = 1; i <= aktor.factor; i++) {
                        if (actionsList.filter(i => i.type === 'dim').length > 0) {
                            let found;
                            actionsList.forEach((item, itemIdx) => {
                                if (item.type === 'dim' && !found) {
                                    found = itemIdx;
                                }
                            });
                            if (found !== undefined) {
                                const item = actionsList[found];
                                product.actions.push(item);
                                actions.push({...item, device: austattung_produkt[aktor.key]});
                                actionsList.splice(found, 1);
                            }
                        }
                    }
                    productList.push(product);
                }
                countForDimmen -= c * aktor.factor;
            }
        });
    }
    const schaltJalousieAktoren = [
        {factor1: 12, factor: 24, key: "sch-aktor-24-12"},
        {factor1: 8, factor: 16, key: "sch-aktor-16-8"},
        {factor1: 4, factor: 8, key: "sch-aktor-8-4"},
        {factor1: 3, factor: 6, key: "sch-aktor-6-3"},
        {factor1: 2, factor: 4, key: "sch-aktor-4-2"},
    ];
    // if(countForRollade > 0) {
    //     schaltJalousieAktoren.forEach(aktor => {
    //         if(countForRollade >=  aktor.factor && austattung_produkt[aktor.key] !== undefined){
    //             const c = Math.ceil(countForRollade / aktor.factor);
    //             for (let a = 1; a <= c; a++) {
    //                 let product = {
    //                     product: austattung_produkt[aktor.key],
    //                     count: 1,
    //                     actions: []
    //                 };
    //                 for (let i = 1; i <= aktor.factor; i++) {
    //                     if (actionsList.filter(i => i.type === 'shading_shutter').length > 0) {
    //                         let found;
    //                         actionsList.forEach((item, itemIdx) => {
    //                             if (item.type === 'shading_shutter' && !found) {
    //                                 found = itemIdx;
    //                             }
    //                         });
    //                         if (found !== undefined) {
    //                             const item = actionsList[found];
    //                             product.actions.push(item);
    //                             actions.push({...item, device: austattung_produkt[aktor.key]});
    //                             actionsList.splice(found, 1);
    //                         }
    //                     }
    //                 }
    //                 productList.push(product);
    //             }
    //             countForRollade -= c * aktor.factor;
    //         }
    //     });
    //     if(countForRollade  > 0 && austattung_produkt["sch-aktor-4-2"] !== undefined){
    //         let pproduct = {
    //             product: austattung_produkt["sch-aktor-4-2"],
    //             count: 1,
    //             actions: []
    //         };
    //         for (let i = 1; i <= 4; i++) {
    //             if (actionsList.filter(it => it.type === 'shading_shutter').length > 0) {
    //                 let found;
    //                 actionsList.forEach((item, itemIdx) => {
    //                     if (item.type === 'shading_shutter' && !found) {
    //                         found = itemIdx;
    //                     }
    //                 });
    //                 if (found !== undefined) {
    //                     const item = actionsList[found];
    //                     pproduct.actions.push(item);
    //                     actions.push({...item, device: austattung_produkt["sch-aktor-4-2"]});
    //                     actionsList.splice(found, 1);
    //                 }
    //             }
    //         }
    //         productList.push(pproduct);
    //     }
    // }
    // if (countForRollade > 0) { countForCheck -= 4 - (countForRollade * 2); }
    if (countForCheck > 0 || countForRollade > 0) {
        let chanels = (2 * countForRollade) + countForCheck;
        schaltJalousieAktoren.forEach(aktor => {
            if(chanels >= aktor.factor && austattung_produkt[aktor.key] !== undefined){
                const c = Math.ceil(chanels / aktor.factor);
                for (let a = 1; a <= c; a++) {
                    let product = {
                        product: austattung_produkt[aktor.key],
                        count: 1,
                        actions: []
                    };
                    for (let i = 1; i <= aktor.factor; i++) {
                        if (actionsList.filter(i => i.type === 'switch_light' || i.type === 'switch_powerplug').length > 0) {
                            let found;
                            actionsList.forEach((item, itemIdx) => {
                                if ((item.type === 'switch_light' || item.type === 'switch_powerplug') && !found) {
                                    found = itemIdx;
                                }
                            });
                            if (found !== undefined) {
                                const item = actionsList[found];
                                product.actions.push(item);
                                actions.push({...item, device: austattung_produkt[aktor.key]});
                                actionsList.splice(found, 1);
                            }
                        } else if (actionsList.filter(i => i.type === 'shading_shutter').length > 0) {
                            let found;
                            actionsList.forEach((item, itemIdx) => {
                                if ((item.type === 'shading_shutter') && !found) {
                                    found = itemIdx;
                                }
                            });
                            if (found !== undefined) {
                                const item = actionsList[found];
                                product.actions.push(item);
                                actions.push({...item, device: austattung_produkt[aktor.key]});
                                actionsList.splice(found, 1);
                                i += 1;
                            }
                        }
                    }
                    productList.push(product);
                }
                chanels -= c * aktor.factor;
            } else if (aktor.factor > chanels && austattung_produkt[aktor.key] !== undefined) {
                const c = chanels / aktor.factor;
                if (c > 0.75 && c < 1) {
                    let product = {
                        product: austattung_produkt[aktor.key],
                        count: 1,
                        actions: []
                    };
                    chanels -= aktor.factor;
                    for (let i = 1; i <= aktor.factor; i++) {
                        if (actionsList.filter(it => it.type === 'switch_light' || it.type === 'switch_powerplug').length > 0) {
                            let found;
                            actionsList.forEach((item, itemIdx) => {
                                if ((item.type === 'switch_light' || item.type === 'switch_powerplug') && found === undefined) {
                                    found = itemIdx;
                                }
                            });
                            if (found !== undefined) {
                                const item = actionsList[found];
                                product.actions.push(item);
                                actions.push({...item, device: austattung_produkt[aktor.key]});
                                actionsList.splice(found, 1);
                            }
                        } else if (actionsList.filter(i => i.type === 'shading_shutter').length > 0) {
                            let found;
                            actionsList.forEach((item, itemIdx) => {
                                if ((item.type === 'shading_shutter') && !found) {
                                    found = itemIdx;
                                }
                            });
                            if (found !== undefined) {
                                const item = actionsList[found];
                                product.actions.push(item);
                                actions.push({...item, device: austattung_produkt[aktor.key]});
                                actionsList.splice(found, 1);
                                i += 1;
                            }
                        }
                    }
                    productList.push(product);
                }
            }
        });
        if(chanels  > 0 && austattung_produkt["sch-aktor-4-2"] !== undefined){
            let product = {
                product: austattung_produkt["sch-aktor-4-2"],
                count: 1,
                actions: []
            };
            for (let i = 1; i <= 4; i++) {
                if (actionsList.filter(it => it.type === 'switch_light' || it.type === 'switch_powerplug').length > 0) {
                    let found;
                    actionsList.forEach((item, itemIdx) => {
                        if ((item.type === 'switch_light' || item.type === 'switch_powerplug') && found === undefined) {
                            found = itemIdx;
                        }
                    });
                    if (found !== undefined) {
                        const item = actionsList[found];
                        product.actions.push(item);
                        actions.push({...item, device: austattung_produkt["sch-aktor-4-2"]});
                        actionsList.splice(found, 1);
                    }
                } else if (actionsList.filter(i => i.type === 'shading_shutter').length > 0) {
                    let found;
                    actionsList.forEach((item, itemIdx) => {
                        if ((item.type === 'shading_shutter') && !found) {
                            found = itemIdx;
                        }
                    });
                    if (found !== undefined) {
                        const item = actionsList[found];
                        product.actions.push(item);
                        actions.push({...item, device: austattung_produkt["sch-aktor-4-2"]});
                        actionsList.splice(found, 1);
                        i += 1;
                    }
                }
            }
            productList.push(product);
        }
    }
    actionsList.forEach(item => {actions.push(item)});
    // console.log('actionlist', actionsList, productList)

    const smart = ["smart-haus", "smart-haus-mobil" , "smart-haus-fern", "smart-haus-sprache"];
    const smart_matrix = [
        {switches: [0, 0, 0, 1], pKeys: ["server"]},
        {switches: [0, 0, 1, 0], pKeys: ["server"]},
        {switches: [0, 0, 1, 1], pKeys: ["server"]},
        {switches: [0, 1, 0, 0], pKeys: ["server"]},
        {switches: [0, 1, 0, 1], pKeys: ["server"]},
        {switches: [0, 1, 1, 0], pKeys: ["server"]},
        {switches: [0, 1, 1, 1], pKeys: ["server"]},
        {switches: [1, 0, 0, 0], pKeys: ["sp8"]},
        {switches: [1, 0, 0, 1], pKeys: ["sc8", "server"]},
        {switches: [1, 0, 1, 0], pKeys: ["sc8", "server"]},
        {switches: [1, 0, 1, 1], pKeys: ["sc8", "server"]},
        {switches: [1, 1, 0, 0], pKeys: ["sp8"]},
        {switches: [1, 1, 0, 1], pKeys: ["sc8", "server"]},
        {switches: [1, 1, 1, 0], pKeys: ["sc8", "server"]},
        {switches: [1, 1, 1, 1], pKeys: ["sc8", "server"]},
    ];
    smart_matrix.forEach((item, mainIdx) => {
        let match = true;
        item.switches.forEach((value, idx) => {
            match = match
                && (
                    (project.selectedEquipment[smart[idx]] !== undefined && project.selectedEquipment[smart[idx]] === value)
                    || (project.selectedEquipment[smart[idx]] === undefined && value === 0)
                )
        });
        if (match) {
            item.pKeys.forEach(key => {
                if (key === 'server') {
                    let alreadyAdded = project.selectedEquipment['sound'] !== undefined && project.selectedEquipment['sound'] > 0;
                    if(!alreadyAdded) {
                        productList.push({
                            product: austattung_produkt[key],
                            count: 1
                        });
                    }
                } else {
                    productList.push({
                        product: austattung_produkt[key],
                        count: 1
                    });
                }
            });
        }
    });
    return {list: productList, actions};
}

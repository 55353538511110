import knx_austattung_produkt from "../data/knx_austattung_produkt.json";
import knx_austattung_produkt_en from "../data/knx_austattung_produkt_en.json";
import knx_austattung_produkt_fr from "../data/knx_austattung_produkt_fr.json";
import knx_austattung_produkt_it from "../data/knx_austattung_produkt_it.json";
import knx_austattung_produkt_nl from "../data/knx_austattung_produkt_nl.json";
import knx_austattung_produkt_at from "../data/knx_austattung_produkt_at.json";
import knx_austattung_produkt_es from "../data/knx_austattung_produkt_es.json";
import konnventionell_austattung_produkt_en from "../data/konventionell_austattung_produkt_en.json";
import konnventionell_austattung_produkt_fr from "../data/konventionell_austattung_produkt_fr.json";
import konnventionell_austattung_produkt_it from "../data/konventionell_austattung_produkt_it.json";
import konnventionell_austattung_produkt_nl from "../data/konventionell_austattung_produkt_nl.json";
import konnventionell_austattung_produkt_at from "../data/konventionell_austattung_produkt_at.json";
import konnventionell_austattung_produkt_es from "../data/konventionell_austattung_produkt_es.json";
import konnventionell_austattung_produkt from "../data/konventionell_austattung_produkt.json";
import home_austattung_produkt_en from "../data/home_austattung_produkt_en.json";
import home_austattung_produkt_fr from "../data/home_austattung_produkt_fr.json";
import home_austattung_produkt_it from "../data/home_austattung_produkt_it.json";
import home_austattung_produkt_nl from "../data/home_austattung_produkt_nl.json";
import home_austattung_produkt_at from "../data/home_austattung_produkt_at.json";
import home_austattung_produkt_es from "../data/home_austattung_produkt_es.json";
import home_austattung_produkt from "../data/home_austattung_produkt.json";
import KnxGenerateProjectService from "./knx_ausstattung/GenerateProjectService";
import products_en from "../data/products_en.json";
import products_es from "../data/products_es.json";
import products_nl from "../data/products_nl.json";
import products_fr from "../data/products_fr.json";
import products_it from "../data/products_it.json";
import products_at from "../data/products_at.json";


export default function GetAusstattungProduktObj(project, room, lang){
    let t = [];
    let knx_product = knx_austattung_produkt;
    let konventionell_product = konnventionell_austattung_produkt;
    let home_product = home_austattung_produkt;

    switch (lang) {
        case 'en' : knx_product = knx_austattung_produkt_en; konventionell_product = konnventionell_austattung_produkt_en; home_product = home_austattung_produkt_en; break;
        case 'fr' : knx_product = knx_austattung_produkt_fr; konventionell_product = konnventionell_austattung_produkt_fr; home_product = home_austattung_produkt_fr; break;
        case 'it' : knx_product = knx_austattung_produkt_it; konventionell_product = konnventionell_austattung_produkt_it; home_product = home_austattung_produkt_it; break;
        case 'nl' : knx_product = knx_austattung_produkt_nl; konventionell_product = konnventionell_austattung_produkt_nl; home_product = home_austattung_produkt_nl; break;
        case 'at' : knx_product = knx_austattung_produkt_at; konventionell_product = konnventionell_austattung_produkt_at; home_product = home_austattung_produkt_at; break;
        case 'es' : knx_product = knx_austattung_produkt_es; konventionell_product = konnventionell_austattung_produkt_es; home_product = home_austattung_produkt_es; break;
    }
    switch(project.projectType){
        case "knx":
            console.log('austattung', room, knx_product);
            t = knx_product.filter(p => (
                p.serie.toString() === room.selectedDesign.selectedSerie.id.toString()
                && p.wippe.toString() === room.selectedDesign.selectedColor1.colorId.toString()
                && p.rahmen.toString() === room.selectedDesign.selectedColor2.colorId.toString()
            ));
            break;
        case "konventionell":
            t = konventionell_product.filter(p => (
                p.serie.toString() === room.selectedDesign.selectedSerie.id.toString()
                && p.wippe.toString() === room.selectedDesign.selectedColor1.colorId.toString()
                && p.rahmen.toString() === room.selectedDesign.selectedColor2.colorId.toString()
            ));
            // console.log('konventionell ausstattung product', room.selectedDesign, t)
            break;
        case "home":
            if (['JUNG-LC_home', 'JUNG-A-Cube_home', 'JUNG-A-Viva_home', 'JUNG-Aflow_home'].includes(room.selectedDesign.selectedSerie.id.toString())) {
                t = home_product.filter(p => (
                    p.serie.toString() === room.selectedDesign.selectedSerie.id.toString()
                    && p.wippe.toString() === room.selectedDesign.selectedColor1.colorId.toString()
                    && p.rahmen.toString() === room.selectedDesign.selectedColor2.colorId.toString()
                ));
            } else {
                t = home_product.filter(p => (
                    p.serie.toString() === room.selectedDesign.selectedSerie.id.toString()
                    && p['scha-aufsatz-2'].toString() === room.selectedDesign.selectedColor1.colorId.toString()
                    && p.rahmen.toString() === room.selectedDesign.selectedColor2.colorId.toString()
                ));
            }
            break;
        default:
            t = [];
    }

    if(t.length > 0){
        return t[0];
    }
    return t;
}

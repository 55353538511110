
import GetAusstattungProduktObj from "../AusstattungProduktService";
import getRauchmelder from "./AddRauchmerlder"
import getBewegungsmelder from "./AddBewegungsmelder"
import getSteckdosen from "./AddSteckdosen"
import getSteckdosenAufputz from "./AddSteckdosenAufputz"
import getSteckdosenUsb from "./AddSteckdosenUsb"
import getSteckdosenRahmen from "./AddSteckdosenRahmen"
import getTelefonDatenanschluss from "./AddTelefonDatenanschluss"
import getTelefonanschluss from "./AddTelefonanschluss"
import getRadioDatenSat from "./AddRadioDatenSat"
import getRadioDatenKabel from "./AddRadioDatenKabel"
import getAnschlussdosenRahmen from "./AddAnschlussdosenRahmen"
import getTuerkommunikation from "./AddTuerkommunikation"
// import getHeizungRaum from "./AddHeizungRaum"
import getTaster from "./AddTaster"
import getAktoren from "../knx_ausstattung/AddAktoren";
import getSound from "./AddSound";
import getAudio from "./AddAudio";

export default function GenerateProjectService(projectIn, productList, lang){

    let generatedProject = {
        code: null,
        name: projectIn.name,
        selectedAlternatives: (projectIn.selectedAlternatives !== undefined) ? projectIn.selectedAlternatives : {},
        floors: []
    };
    let systemProductList = [];
    let addSpannung = false;
    let actionsList = [];
    projectIn.floors.forEach( floorIn => {
        const floor = {
            id: floorIn.id,
            name: floorIn.name,
            type: floorIn.typePro,
            rooms: []
        };
        floorIn.rooms.forEach(roomIn => {
            const room = {
                id: roomIn.id,
                name: roomIn.name,
                type: roomIn.typePro,
                products: [],
                actions: []
            }
            const austattung_produkt = GetAusstattungProduktObj(projectIn, roomIn, lang);
            let productListInner = []; let optionalRahmen = [];

            //todo use algorithm to fill productListInner and systemProductList
            productListInner = productListInner.concat(getRauchmelder(roomIn, austattung_produkt));
            productListInner = productListInner.concat(getBewegungsmelder(roomIn, austattung_produkt));
            productListInner = productListInner.concat(getSteckdosen(roomIn, austattung_produkt));
            productListInner = productListInner.concat(getSteckdosenAufputz(roomIn, austattung_produkt));
            productListInner = productListInner.concat(getSteckdosenUsb(roomIn, austattung_produkt));
            productListInner = productListInner.concat(getSteckdosenRahmen(roomIn, austattung_produkt));
            productListInner = productListInner.concat(getTelefonDatenanschluss(roomIn, austattung_produkt));
            productListInner = productListInner.concat(getTelefonanschluss(roomIn, austattung_produkt));
            productListInner = productListInner.concat(getRadioDatenSat(roomIn, austattung_produkt));
            productListInner = productListInner.concat(getRadioDatenKabel(roomIn, austattung_produkt));
            if (projectIn.subProjectType === 'umbau') {
                optionalRahmen = optionalRahmen.concat(getAnschlussdosenRahmen(roomIn, austattung_produkt));
            } else {
                productListInner = productListInner.concat(getAnschlussdosenRahmen(roomIn, austattung_produkt));
            }
            productListInner = productListInner.concat(getAudio(roomIn, austattung_produkt));
            const taster = getTaster(roomIn, austattung_produkt, projectIn.selectedAlternatives, []);
            console.log('taster', taster)
            if(taster.list.length > 0){
                productListInner = productListInner.concat(taster.list);
                actionsList = actionsList.concat(taster.actions);
                room.actions = room.actions.concat(taster.actions);
            }

            productListInner.forEach( p => {
                if (!p.product || p.product === '') { console.log('skipping empty product', p); return; }
                const productTmp = room.products.find(pi => pi.Artikelnummer.toString() === p.product.toString());
                if(productTmp === undefined){
                    const pp = productList.find(pl => pl.Artikelnummer.toString() === p.product.toString());
                    if(pp === undefined){ console.log('product not found', p); return; }
                    const productToPush = {...pp, count: p.count, actions: p.actions ? p.actions: []};
                    if(p.alt !== undefined){
                        const nlist = p.alt.map(pa => {
                            const altList = [];
                            pa.products.forEach(paPro => {
                                const ppa = productList.find(pl => pl.Artikelnummer.toString() === paPro.toString());
                                if(pp === undefined){
                                    return;
                                }
                                altList.push(ppa);
                            })
                            pa.products = altList;
                            return pa;
                        });
                        productToPush.productSwitch = nlist;
                    }
                    room.products.push(productToPush);
                }else {
                    productTmp.count += p.count;
                    productTmp.actions = productTmp.actions.concat(p.actions || []);
                }
            });
            optionalRahmen.forEach( p => {
                if (!p.product || p.product === '') { console.log('skipping empty product', p); return; }
                const productTmp = room.products.find(pi => pi.Artikelnummer.toString() === p.product.toString() && p.isOptional === true);
                if(productTmp === undefined){
                    const pp = productList.find(pl => pl.Artikelnummer.toString() === p.product.toString());
                    if(pp === undefined){ console.log('product not found', p); return; }
                    const productToPush = {...pp, count: p.count, actions: p.actions ? p.actions: [], isOptional: true};
                    if(p.alt !== undefined){
                        const nlist = p.alt.map(pa => {
                            const altList = [];
                            pa.products.forEach(paPro => {
                                const ppa = productList.find(pl => pl.Artikelnummer.toString() === paPro.toString());
                                if(pp === undefined){
                                    return;
                                }
                                altList.push(ppa);
                            })
                            pa.products = altList;
                            return pa;
                        });
                        productToPush.productSwitch = nlist;
                    }
                    room.products.push(productToPush);
                }else {
                    productTmp.count += p.count;
                    productTmp.actions = productTmp.actions.concat(p.actions || []);
                }
            });
            floor.rooms.push(room);
        });
        generatedProject.floors.push(floor);
    });
    const austattung_produkt = projectIn.floors.length && projectIn.floors[0].rooms.length ? GetAusstattungProduktObj(projectIn, projectIn.floors[0].rooms[0], lang) : [];
    //todo use algorithm to fill systemProductList
    systemProductList = [];
    getTuerkommunikation(projectIn, austattung_produkt).forEach(p => {
        if (p.product !== undefined) {
            const pz = productList.find(pl => pl.Artikelnummer.toString() === p.product.toString());
            if (pz) {
                generatedProject = {
                    ...generatedProject,
                    floors: generatedProject.floors.map(floor => {
                        return {
                            ...floor,
                            rooms: floor.rooms.map(room => {
                                if (p.room.typePro === room.type && !p.assigned) {
                                    p = {...p, assigned: true};
                                    return {
                                        ...room,
                                        products: [...room.products, {...pz, count: p.count}]
                                    }
                                }
                                return room;
                            })
                        };
                    })
                };
            }
        }
    });
    systemProductList = systemProductList.concat(getSound(projectIn, austattung_produkt));
    const akt = getAktoren(projectIn, austattung_produkt, actionsList);
    if(akt.length > 0){
        addSpannung = true;
        systemProductList = systemProductList.concat(akt);
    }
    if(addSpannung){
        systemProductList.push(
            {
                product: austattung_produkt["spannung"],
                count: 1
            }
        );
    }
    if(systemProductList.length > 0){
        const uv = {
            id: "Unterverteilung", //uuidv4(), todo
            name: "Unterverteilung",
            type: "cc245399-46d7-42fc-a576-0d11ecf0db71",
            products: [],
            installationLocations: [{
                id: "InstallationLocation",
                name: "[Unterverteiler]",
                type: "4e6ab71c-beee-4f8b-9c77-1bc98394d104",
                isSubdistribution: true,
                products: [],
                actions: []
            }]
        }
        const uvProducts = [];
        systemProductList.forEach( p => {
            const productTmp = uvProducts.find(pi => pi.Artikelnummer.toString() === p.product.toString());
            if(productTmp === undefined){
                const pp = productList.find(pl => pl.Artikelnummer.toString() === p.product.toString());
                if(pp === undefined){
                    return;
                }
                const productToPush = {...pp, count: p.count};
                if(p.alt !== undefined){
                    const nlist = p.alt.map(pa => {
                        const altList = [];
                        pa.products.forEach(paPro => {
                            const ppa = productList.find(pl => pl.Artikelnummer? pl.Artikelnummer.toString() === paPro.toString() : false);
                            if(pp === undefined){
                                return;
                            }
                            altList.push(ppa);
                        })
                        pa.products = altList;
                        return pa;
                    });
                    productToPush.productSwitch = nlist;
                }
                uvProducts.push(productToPush);
            }else {
                productTmp.count += p.count;
            }
        });
        uvProducts.forEach(i => uv.installationLocations[0].products.push(i));
        uvProducts.forEach(i => uv.products.push(i));
        generatedProject.floors[0].rooms.push(uv);
    }
    return {...projectIn, generatedProject: generatedProject}

}



export default function getProducts(room, austattung_produkt){

    const productList = [];
    if(
        room.selectedEquipment["usb_steckdosen"] !== undefined
        && room.selectedEquipment["usb_steckdosen"] > 0
        && austattung_produkt["usb-steckdosen"] !== undefined
    ){
        productList.push({
            product: austattung_produkt["usb-steckdosen"],
            count: room.selectedEquipment["usb_steckdosen"]
        })
    }
    return productList;
}

import {v4 as uuidv4} from "uuid";


export default function getProducts(project, austattung_produkt, actionsList){

    const productList = [];
    const actions = [];
    let fussboden = 0; let heating = 0;
    const newActions = [];

    function getNewUuid() {
        const uuid = uuidv4();
        const found = actionsList.filter(i => i.uuid === uuid)[0] !== undefined;
        if (found) { return getNewUuid(); }
        return uuid;
    }

    project.floors.forEach(floor => {
        floor.rooms.forEach(room => {
            if(
                room.selectedEquipment["heizen_fußbodenheizung"] !== undefined
                && Boolean(room.selectedEquipment["heizen_fußbodenheizung"])
            ){
                fussboden += 1;
            }
            if(
                room.selectedEquipment["heizen_heizkoerper"] !== undefined
                && Boolean(room.selectedEquipment["heizen_heizkoerper"])
            ){
                heating += 1;
            }
        })
    })

    let totalHeating = fussboden + heating;
    const aktoren = [
        {key: "rt-aktor-6", channels: 6},
    ];
    if (!actionsList.length) {
        for(let i = 1; i <= totalHeating; i++) {
            const newUUID = getNewUuid();
            const a = {type: 'heating_PWM', uuid: newUUID, device: null, addedIn: 'AddHeizung', puid: ''};
            actionsList.push(a); newActions.push(a);
        }
    }

    aktoren.forEach(aktor => {
        const c = Math.ceil(totalHeating / aktor.channels);
        for(let i = 1; i <= c; i++) {
            const p = {
                product: austattung_produkt[aktor.key],
                count: 1,
                actions: []
            };
            for (let k = 1; k <= aktor.channels; k++) {
                if (actionsList.length > 0) {
                    const item = actionsList[0];
                    actionsList.splice(0, 1);
                    actions.push({...item, device: austattung_produkt[aktor.key]});
                    p.actions.push({...item, device: austattung_produkt[aktor.key]});
                }
            }
            productList.push(p);
        }
        totalHeating -= c * aktor.channels;
        // for (let i = 1; i <= c * aktor.channels; i++) {
        //     if (actionsList.length > 0) {
        //         const item = actionsList[0];
        //         actionsList.splice(0, 1);
        //         actions.push({...item, device: austattung_produkt[aktor.key]});
        //     }
        // }
    });

    return {list: productList, actions, newActions};

}
